import React, { useState, useEffect } from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
} from '@mui/material';
import { useFilters } from 'modules/filters/context/filtersContext';

const SearchBox = () => {
  const {
    searchType,
    updateSearchType,
    updateSearchValue,
    resetPage,
    searchValue,
  } = useFilters();

  const [userSearchType, setUserSearchType] = useState(searchType);
  const [userSearch, setUserSearch] = useState('');

  const handleSearchValueChange = ({ target }) => {
    const { value } = target;

    if (value.length < 3) {
      setUserSearch('');
    } else {
      setUserSearch(value);
    }
  };

  useEffect(() => {
    if (searchValue !== userSearch) {
      const timeoutID = setTimeout(() => {
        updateSearchValue(userSearch);
        updateSearchType(userSearchType);
        resetPage();
      }, 500);
      return () => clearTimeout(timeoutID);
    }
    return () => {};
  }, [
    resetPage,
    updateSearchType,
    updateSearchValue,
    userSearch,
    userSearchType,
    searchValue,
  ]);

  const handleSelectOptionChange = ({ target }) => {
    const { value } = target;
    setUserSearchType(value);
    if (searchValue) {
      updateSearchType(value);
      resetPage();
    }
  };

  return (
    <>
      <TextField
        label="Search for users (min. 3 characters)"
        onChange={handleSearchValueChange}
        sx={styles.textField}
      />
      <FormControl>
        <InputLabel>Search by</InputLabel>
        <Select
          value={userSearchType}
          label="Search By"
          onChange={handleSelectOptionChange}
          autoWidth
          sx={styles.select}
        >
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="email">Email</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

const styles = {
  textField: { flexGrow: '9999', margin: '0 0.5em 0.5em 0', minWidth: '25em' },
  select: { marginRight: '.5em' },
};

export default SearchBox;
