import React from 'react';
import { Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';

const RightJustifiedButton = ({ text, onClick, type }) => {
  return (
    <Grid justifyContent="right" container>
      <Button
        sx={styles.button}
        onClick={onClick}
        variant="outlined"
        color={type}
      >
        {text}
      </Button>
    </Grid>
  );
};

const styles = {
  button: { height: '4em', minWidth: '10em', marginTop: '1em' },
};

RightJustifiedButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

RightJustifiedButton.defaultProps = {
  text: '',
  onClick: () => {},
  type: 'success',
};
export default RightJustifiedButton;
