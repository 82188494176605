import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import rolesReducer, { initialState } from 'modules/user/context/userReducer';

import {
  SET_UPDATED_ROLES,
  SET_USER_TYPE,
  CHANGE_DATASET_AUTH,
  SET_USER,
  RESET_USER,
  SET_USER_EMAIL,
  SET_USER_NAME,
  SET_UPDATED_STATUS_NOTIFICATIONS,
} from 'modules/user/context/actions';
import { parseChanges, parseNotificationChanges } from 'modules/user/helpers';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rolesReducer, initialState);
  const {
    updatedRoles,
    updatedStatusNotifications,
    statusNotifications,
    statusNotificationChanges,
  } = state;

  const setUser = (userObj) => {
    dispatch({ type: SET_USER, payload: userObj });
  };

  const setUserName = (name) => {
    dispatch({ type: SET_USER_NAME, payload: name });
  };

  const setUserEmail = (email) => {
    dispatch({ type: SET_USER_EMAIL, payload: email });
  };

  const setDatasetAuthorizationChanges = (changes) => {
    dispatch({ type: CHANGE_DATASET_AUTH, payload: changes });
  };

  const setUpdatedRoles = (roles, changes) => {
    dispatch({ type: SET_UPDATED_ROLES, payload: { roles, changes } });
  };

  const setUpdatedStatusNotifications = (updatedNotifications, changes) => {
    dispatch({
      type: SET_UPDATED_STATUS_NOTIFICATIONS,
      payload: { updatedStatusNotifications: updatedNotifications, changes },
    });
  };

  const setAccountType = (type) => {
    dispatch({ type: SET_USER_TYPE, payload: type });
  };

  const handleNotificationChange = useCallback(
    (dataset, dataStore, checked) => {
      const notifications = { ...updatedStatusNotifications };

      if (!notifications[dataset]) {
        notifications[dataset] = [];
      }

      if (checked) {
        notifications[dataset].push(dataStore);
      } else {
        notifications[dataset] = notifications[dataset].filter(
          (store) => store !== dataStore
        );
      }

      const changes = parseNotificationChanges(
        statusNotifications,
        statusNotificationChanges,
        dataset,
        dataStore,
        checked
      );

      setUpdatedStatusNotifications(notifications, changes);
    },
    [statusNotificationChanges, statusNotifications, updatedStatusNotifications]
  );

  const handleRoleChange = useCallback(
    (dataset, dataStore, originalRoles, newRole) => {
      const currentRoles = { ...updatedRoles };
      if (!currentRoles[dataset]) {
        currentRoles[dataset] = {};
      }
      currentRoles[dataset][dataStore] = newRole;
      const changes = parseChanges(originalRoles, currentRoles);
      setUpdatedRoles(currentRoles, changes);
    },
    [updatedRoles]
  );

  const resetUser = () => {
    dispatch({ type: RESET_USER });
  };

  const value = useMemo(() => {
    return {
      ...state,
      handleRoleChange,
      handleNotificationChange,
      setAccountType,
      resetUser,
      setDatasetAuthorizationChanges,
      setUser,
      setUserEmail,
      setUserName,
    };
  }, [state, handleRoleChange, handleNotificationChange]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

UserProvider.defaultProps = {
  children: {},
};

export const useUser = () => {
  return useContext(UserContext);
};
