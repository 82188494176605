import React from 'react';
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { useFilters } from 'modules/filters/context/filtersContext';
import useFetchedAdminDataStores from 'modules/admin/useFetchedAdminDataStores';
import { Loading } from 'modules/common/components';

const DataStoreDropDown = () => {
  const {
    selectedDataset,
    selectedDataStore,
    setselectedDataStore,
    resetPage,
  } = useFilters();

  const {
    isLoading: isDataStoresLoading,
    isError: isDataStoresError,
    data: adminDataStoresInSelectedDataset,
  } = useFetchedAdminDataStores(selectedDataset);

  const handleDicomStoreSelectChange = (event) => {
    let {
      target: { value },
    } = event;

    if (value === 'all') {
      value = '';
    }
    setselectedDataStore(value);
    resetPage();
  };

  const renderDataStores = () => {
    if (isDataStoresLoading) {
      return <Loading />;
    }
    if (isDataStoresError) {
      return <p>Error</p>;
    }

    return adminDataStoresInSelectedDataset.map((store) => (
      <MenuItem key={store} value={store}>
        {store}
      </MenuItem>
    ));
  };

  if (selectedDataset) {
    return (
      <FormControl sx={styles.formControl}>
        <InputLabel>MRI Systems</InputLabel>
        <Select
          value={selectedDataStore || 'all'}
          onChange={handleDicomStoreSelectChange}
          autoWidth
        >
          <MenuItem key="all" value="all">
            All
          </MenuItem>
          {renderDataStores()}
        </Select>
      </FormControl>
    );
  }
  return null;
};

const styles = {
  formControl: {
    flexGrow: '1',
    marginRight: '.5em',
    minWidth: '6em',
  },
};

export default DataStoreDropDown;
