import React, { useEffect } from 'react';
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { useFilters } from 'modules/filters/context/filtersContext';
import useAdminProfile from 'modules/admin/useAdminProfile';

const DataSetDropDown = () => {
  const { selectedDataset, setSelectedDataset, resetPage } = useFilters();
  const { data: { allDatasets = [] } = {} } = useAdminProfile();

  useEffect(() => {
    if (allDatasets && allDatasets.length === 1) {
      setSelectedDataset(allDatasets[0]);
    }
  }, [allDatasets, setSelectedDataset]);

  const handleDatasetSelectChange = ({ target }) => {
    let { value } = target;
    if (value === 'all') {
      value = '';
    }
    setSelectedDataset(value);
    resetPage();
  };

  const renderDataset = () => {
    const datasets = [
      <MenuItem key="all" value="all">
        All
      </MenuItem>,
    ];

    allDatasets.forEach((set) =>
      datasets.push(
        <MenuItem key={set} value={set}>
          {set}
        </MenuItem>
      )
    );
    return datasets;
  };

  if (allDatasets.length > 1) {
    return (
      <FormControl sx={styles.formControl}>
        <InputLabel>Institutions</InputLabel>
        <Select
          value={selectedDataset || 'all'}
          onChange={handleDatasetSelectChange}
          autoWidth
        >
          {renderDataset()}
        </Select>
      </FormControl>
    );
  }
  return null;
};

const styles = {
  formControl: {
    flexGrow: '1',
    marginRight: '.5em',
    minWidth: '6em',
  },
};

export default DataSetDropDown;
