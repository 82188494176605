import {
  INCREASE_PAGE,
  DECREASE_PAGE,
  RESET_FILTERS,
  UPDATE_SEARCH_TYPE,
  UPDATE_SEARCH_VALUE,
  SET_SELECTED_DATASET,
  SET_SELECTED_DICOM_STORE,
  RESET_PAGE,
  SET_PROFILE_SELECTED_DATASET,
  SET_MRI_SUPER_ADMIN_MODE,
} from 'modules/filters/context/actions';

export const initialState = {
  page: 0,
  searchValue: '',
  searchType: 'name',
  selectedDataset: '',
  selectedDataStore: '',
  profileSelectedDataset: '',
  mriSuperAdminMode: false,
};

const filtersReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_DATASET: {
      const { dataset, selectedDataStore } = payload;
      return {
        ...state,
        selectedDataset: dataset,
        selectedDataStore,
      };
    }

    case SET_SELECTED_DICOM_STORE:
      return { ...state, selectedDataStore: payload };

    case INCREASE_PAGE:
      return { ...state, page: state.page + 1 };

    case DECREASE_PAGE:
      return { ...state, page: state.page - 1 };

    case RESET_PAGE:
      return { ...state, page: 0 };

    case RESET_FILTERS:
      return { ...initialState };

    case UPDATE_SEARCH_TYPE:
      return { ...state, searchType: payload };

    case UPDATE_SEARCH_VALUE:
      return { ...state, searchValue: payload };

    case SET_PROFILE_SELECTED_DATASET:
      return { ...state, profileSelectedDataset: payload };

    case SET_MRI_SUPER_ADMIN_MODE: {
      return { ...state, mriSuperAdminMode: payload, page: 0 };
    }

    default:
      return state;
  }
};

export default filtersReducer;
