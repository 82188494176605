import React from 'react';
import { TableRow, TableCell, Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import { useUser } from 'modules/user/context/userContext';
import {
  USER_ACCOUNT_TYPE,
  ADMIN_ACCOUNT_TYPE,
  MRI_SUPER_ADMIN_ACCOUNT_TYPE,
} from 'utils/constants';

const UserRow = ({ user }) => {
  const { setUser } = useUser();
  const { name, picture, email, accountType } = user;

  const handleUserRowClick = () => {
    setUser(user);
  };

  const getAccountType = () => {
    switch (accountType) {
      case MRI_SUPER_ADMIN_ACCOUNT_TYPE:
        return 'MRI Super Admin';
      case ADMIN_ACCOUNT_TYPE:
        return 'Admin';
      case USER_ACCOUNT_TYPE:
        return 'User';
      default:
        return 'Unknown';
    }
  };

  return (
    <TableRow hover sx={{ cursor: 'pointer' }} onClick={handleUserRowClick}>
      <TableCell>
        <Avatar
          alt={`${name}'s picture`}
          src={picture}
          sx={{ width: 56, height: 56 }}
        />
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{getAccountType()}</TableCell>
    </TableRow>
  );
};

UserRow.propTypes = {
  user: PropTypes.shape({
    user_id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    picture: PropTypes.string,
    accountType: PropTypes.string,
  }),
};

UserRow.defaultProps = {
  user: {},
};

export default UserRow;
