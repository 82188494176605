import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import { getItemColor } from 'modules/user/helpers';
import { DATASET_CHANGES, STATUS_NOTIFICATION_CHANGES } from 'utils/constants';

const SummaryRow = ({ summary, type }) => {
  if (type === DATASET_CHANGES) {
    const { dataset, change } = summary;
    return (
      <TableRow
        hover
        sx={{ backgroundColor: change === 'added' ? '#CCFFCC' : '#FFCCCC' }}
      >
        <TableCell>{dataset}</TableCell>
        <TableCell>{change}</TableCell>
      </TableRow>
    );
  }

  if (type === STATUS_NOTIFICATION_CHANGES) {
    const { dataset, dataStore, change } = summary;
    return (
      <TableRow
        hover
        sx={{ backgroundColor: change === 'added' ? '#CCFFCC' : '#FFCCCC' }}
      >
        <TableCell>{dataset}</TableCell>
        <TableCell>{dataStore}</TableCell>
        <TableCell>{change}</TableCell>
      </TableRow>
    );
  }

  const { dataset, dataStore, originalRole, newRole } = summary;
  return (
    <TableRow hover sx={{ backgroundColor: getItemColor(summary) }}>
      <TableCell>{dataset}</TableCell>
      <TableCell>{dataStore}</TableCell>
      <TableCell sx={styles.tableCell}>{originalRole}</TableCell>
      <TableCell sx={styles.tableCell}>{newRole}</TableCell>
    </TableRow>
  );
};

const styles = {
  tableCell: { textTransform: 'capitalize' },
};

SummaryRow.propTypes = {
  summary: PropTypes.shape({
    dataset: PropTypes.string,
    dataStore: PropTypes.string,
    originalRole: PropTypes.string,
    newRole: PropTypes.string,
    change: PropTypes.string,
  }),
  type: PropTypes.string,
};

SummaryRow.defaultProps = {
  summary: PropTypes.shape({
    dataset: '',
    dataStore: '',
    originalRole: '',
    newRole: '',
    change: '',
  }),
  type: '',
};

export default SummaryRow;
