import React, { useCallback, useEffect, useState } from 'react';
import { TextField, Typography, Select, MenuItem } from '@mui/material';
import { Loading, Modal } from 'modules/common/components';
import RightJustifiedButton from 'modules/common/components/Buttons/RightJustifiedButton';
import { useModal } from 'modules/modal/context/modalContext';
import useAdminProfile from 'modules/admin/useAdminProfile';
import useGCPMutationHooks from 'modules/modal/hooks/useGCPMutationHooks';

const initialState = {
  initialDataset: 'selectADataset',
  isDatasetError: false,
  currentSelectedDataset: 'selectADataset',
  newDataStoreName: '',
  isNewDataStoreNameError: false,
};

const NewDicomStoreModal = () => {
  const { data: { datasetAuthorization = [] } = {} } = useAdminProfile();
  const { isNewDataStoreModalVisible, setIsNewDataStoreModalVisible } =
    useModal();
  const [state, setState] = useState(initialState);

  const { newDataStoreMutation } = useGCPMutationHooks();
  const { isLoading, isSuccess, reset } = newDataStoreMutation;

  const handleOnNewDicomStoreModalClose = useCallback(() => {
    setIsNewDataStoreModalVisible(false);
    setState(initialState);
  }, [setIsNewDataStoreModalVisible]);

  useEffect(() => {
    if (isSuccess) {
      handleOnNewDicomStoreModalClose();
      reset();
    }
  }, [handleOnNewDicomStoreModalClose, isSuccess, reset]);

  const {
    initialDataset,
    currentSelectedDataset,
    isDatasetError,
    newDataStoreName,
    isNewDataStoreNameError,
  } = state;

  const handleOnSaveButtonClick = () => {
    let dataset = currentSelectedDataset;
    // if only dataset admin in 1 dataset, set that as dataset to
    // create new data store in
    if (datasetAuthorization.length === 1) {
      [dataset] = datasetAuthorization;
    }
    if (dataset === initialDataset) {
      setState((currentState) => {
        return { ...currentState, isDatasetError: true };
      });
      return;
    }
    if (!newDataStoreName) {
      setState((currentState) => {
        return { ...currentState, isNewDataStoreNameError: true };
      });
      return;
    }

    newDataStoreMutation.mutate({
      dataset,
      dataStore: newDataStoreName,
    });
  };

  const handleOnChangeNewDataStoreTextField = ({ target: { value } }) => {
    setState((currentState) => {
      return {
        ...currentState,
        newDataStoreName: value,
        isNewDataStoreNameError: false,
      };
    });
  };

  const handleDatasetSelectChange = ({ target: { value } }) => {
    setState((currentState) => {
      return {
        ...currentState,
        isDatasetError: false,
        currentSelectedDataset: value,
      };
    });
  };

  const renderDatasetInfo = () => {
    if (datasetAuthorization.length > 1) {
      const datasets = [
        <MenuItem key={initialDataset} value={initialDataset}>
          Select an institution
        </MenuItem>,
      ];
      datasetAuthorization.forEach((set) =>
        datasets.push(
          <MenuItem key={set} value={set}>
            {set}
          </MenuItem>
        )
      );
      return (
        <Select
          value={currentSelectedDataset}
          onChange={handleDatasetSelectChange}
          autoWidth
          error={isDatasetError}
        >
          {datasets}
        </Select>
      );
    }
    return (
      <Typography variant="subtitle1">{datasetAuthorization[0]}</Typography>
    );
  };

  if (isLoading) {
    return (
      <Modal
        isOpen={isNewDataStoreModalVisible}
        title="Creating New MRI System..."
      >
        <Loading />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isNewDataStoreModalVisible}
      onClose={handleOnNewDicomStoreModalClose}
      title="New MRI System"
    >
      <Typography style={styles.typography}>Institution:</Typography>
      {renderDatasetInfo()}
      <TextField
        type="text"
        label="MRI System Name"
        value={newDataStoreName}
        onChange={handleOnChangeNewDataStoreTextField}
        sx={styles.textField}
        error={isNewDataStoreNameError}
      />
      <RightJustifiedButton text="Save" onClick={handleOnSaveButtonClick} />
    </Modal>
  );
};

const styles = {
  textField: { flex: 1, margin: '1em 0.5em 0.5em 0', width: '100%' },
};

export default NewDicomStoreModal;
