import React, { useCallback, useState } from 'react';
import { Loading, Modal } from 'modules/common/components';
import UserRoles from 'modules/user/components/UserRoles';
import UserBasicInformation from 'modules/user/components/UserBasicInformation';
import EvenlySpacedButtons from 'modules/common/components/Buttons/EvenlySpacedButtons';
import useMutationHooks from 'modules/user/hooks/useMutationHooks';
import { useUser } from 'modules/user/context/userContext';
import { useModal } from 'modules/modal/context/modalContext';
import SummaryModal from 'modules/modal/components/SummaryModal';
import WarningModal from 'modules/modal/components/WarningModal';
import { createChangeObject, getTitle } from 'modules/user/helpers';

const UserProfile = () => {
  const {
    isSummaryModalVisible,
    setIsSummaryModalVisible,
    isExistingUserModalVisible,
    isWarningModalVisible,
    setIsWarningModalVisible,
  } = useModal();

  const {
    userId,
    accountType,
    rolesChanges,
    resetUser,
    datasetAuthorizationChanges,
    statusNotificationChanges,
  } = useUser();

  const { userRolesMutation, deleteUserMutation } = useMutationHooks();
  const [isUserBeingRemoved, setIsUserBeingRemoved] = useState(false);
  const { isLoading: isDeletingUserLoading } = deleteUserMutation;
  const { isLoading: isUserRoleMutationLoading } = userRolesMutation;

  const handleProfileClose = useCallback(() => {
    resetUser();
  }, [resetUser]);

  if (isDeletingUserLoading || isUserRoleMutationLoading) {
    let title = '';
    if (isDeletingUserLoading) title = 'Deleting User...';
    else title = 'Updating User...';

    return (
      <Modal isOpen={isExistingUserModalVisible} title={title}>
        <Loading />
      </Modal>
    );
  }

  const changesConfirmed = () => {
    if (isUserBeingRemoved) {
      setIsWarningModalVisible(true);
    } else {
      const { changes, notificationChanges } = createChangeObject(
        accountType,
        rolesChanges,
        datasetAuthorizationChanges,
        statusNotificationChanges
      );

      userRolesMutation.mutate({
        userId,
        accountType,
        roleChanges: changes,
        notificationChanges,
      });
    }

    setIsSummaryModalVisible(false);
  };

  const handleOpenSummaryModal = () => {
    if (
      rolesChanges.length ||
      Object.keys(datasetAuthorizationChanges).length ||
      statusNotificationChanges.length
    ) {
      setIsSummaryModalVisible(true);
    } else handleProfileClose();
  };

  const handleCloseSummaryModal = () => {
    if (isUserBeingRemoved) {
      setIsUserBeingRemoved(false);
    }
    setIsSummaryModalVisible(false);
  };

  const handleDeleteClick = () => {
    setIsUserBeingRemoved(true);
    setIsSummaryModalVisible(true);
  };

  const handleWarningConfirm = () => {
    setIsWarningModalVisible(false);
    setIsUserBeingRemoved(false);
    deleteUserMutation.mutate(userId);
  };

  const render = () => {
    return (
      <>
        <UserBasicInformation />
        <UserRoles />
        <p>
          Summary:{' '}
          {rolesChanges.length +
            Object.keys(datasetAuthorizationChanges || {}).length +
            statusNotificationChanges.length}{' '}
          changes made
        </p>
        <SummaryModal
          isOpen={isSummaryModalVisible}
          onClose={handleCloseSummaryModal}
          onBtnClick={changesConfirmed}
          isUserBeingRemoved={isUserBeingRemoved}
        />
        <EvenlySpacedButtons
          leftBtnText="Delete"
          leftBtnOnClick={handleDeleteClick}
          rightBtnText="Save"
          rightBtnOnClick={handleOpenSummaryModal}
        />
        <WarningModal
          isOpen={isWarningModalVisible}
          onClose={() => {
            setIsWarningModalVisible(false);
          }}
          onBtnClick={handleWarningConfirm}
        />
      </>
    );
  };

  return (
    <Modal
      isOpen={isExistingUserModalVisible}
      onClose={handleProfileClose}
      title={getTitle(accountType)}
    >
      {render()}
    </Modal>
  );
};

export default UserProfile;
