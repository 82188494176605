import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@mui/material';
import { LOADING, ERROR, EMPTY } from 'utils/constants';
import Loading from 'modules/common/components/Loading';

const CustomizableRow = ({ rowType, dataType }) => {
  let element;
  switch (rowType) {
    case LOADING:
      element = <Loading />;
      break;
    case ERROR:
      element = <h2>Something went wrong, try again.</h2>;
      break;
    case EMPTY:
      element = <h2>{`No ${dataType} found`}</h2>;
      break;
    default:
      element = null;
  }

  return (
    <TableRow
      hover
      sx={{
        cursor: 'pointer',
      }}
    >
      <TableCell sx={{ textAlign: 'center' }}>{element}</TableCell>
    </TableRow>
  );
};

CustomizableRow.propTypes = {
  rowType: PropTypes.string,
  dataType: PropTypes.string,
};

CustomizableRow.defaultProps = {
  rowType: '',
  dataType: 'results',
};

export default CustomizableRow;
