import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@mui/material';
import { USER_ROLES, ADMIN_ROLES, ADMIN_ACCOUNT_TYPE } from 'utils/constants';
import { useUser } from '../context/userContext';

const SelectRoles = ({ userRole, handleSelectRoleChange }) => {
  const { accountType } = useUser();

  const renderRoles = () => {
    if (accountType === ADMIN_ACCOUNT_TYPE) {
      return ADMIN_ROLES.map((role) => (
        <MenuItem key={role} value={role} sx={styles.menuItem}>
          {role}
        </MenuItem>
      ));
    }
    return USER_ROLES.map((role) => (
      <MenuItem key={role} value={role} sx={styles.menuItem}>
        {role}
      </MenuItem>
    ));
  };

  return (
    <Select
      value={userRole}
      onChange={handleSelectRoleChange}
      sx={styles.select}
    >
      {renderRoles()}
    </Select>
  );
};

const styles = {
  select: {
    marginLeft: '1em',
    textTransform: 'capitalize',
    flexShrink: 0,
    width: '6em',
  },
  menuItem: { textTransform: 'capitalize' },
};

SelectRoles.propTypes = {
  userRole: PropTypes.string,
  handleSelectRoleChange: PropTypes.func,
};

SelectRoles.defaultProps = {
  userRole: '',
  handleSelectRoleChange: () => {},
};

export default SelectRoles;
