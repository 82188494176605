import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal as ModalTemplate,
  Box,
  Typography,
  Collapse,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const Modal = ({ children, title, isOpen, onClose }) => {
  return (
    <Collapse in>
      <ModalTemplate
        BackdropProps={styles.backDrop}
        open={isOpen}
        onClose={onClose}
      >
        <Box sx={styles.modal}>
          {onClose && <CloseIcon sx={styles.closeIcon} onClick={onClose} />}
          <Typography align="center" variant="h4" sx={styles.typography}>
            {title}
          </Typography>
          {children}
        </Box>
      </ModalTemplate>
    </Collapse>
  );
};

const styles = {
  closeIcon: { float: 'right', cursor: 'pointer' },
  backIcon: { float: 'left', cursor: 'pointer' },
  backDrop: {
    sx: { backgroundColor: 'rgba(0, 0, 0, 0.6)' },
  },
  modal: {
    overflow: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '40em',
    maxHeight: '70%',
    borderRadius: '1%',
    boxShadow: 24,
    p: 4,
    bgcolor: 'background.paper',
  },
};

Modal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  children: () => {},
  title: '',
  isOpen: false,
  onClose: null,
};

export default Modal;
