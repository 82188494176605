import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const useAuth = () => {
  const {
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    user,
    error,
    isLoading,
    isAuthenticated,
  } = useAuth0();

  const [accessToken, setAccessToken] = useState('');

  const fetchToken = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      if (token !== accessToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        setAccessToken(token);
      }
    } catch (err) {
      // TODO: add better way to handle and display this error
      console.log(err);
    }
  }, [accessToken, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && !accessToken) {
      fetchToken();
    }
  }, [accessToken, fetchToken, isAuthenticated]);

  return {
    isLoading,
    loginWithRedirect,
    logout,
    user,
    error,
    accessToken,
    isAuthenticated,
  };
};

export default useAuth;
