import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@mui/material';
import StoreListItem from 'modules/user/components/StoreListItem';
import { Loading } from 'modules/common/components';

const RolesTable = ({ isLoading, isError, profileSelectedDataset, stores }) => {
  const render = () => {
    if (isLoading) {
      return <Loading />;
    }
    if (isError) {
      return (
        <ListItem>
          <p>Error...</p>
        </ListItem>
      );
    }
    if (stores.length === 0) {
      return (
        <ListItem>
          <ListItemText
            primary="No MRI systems found"
            sx={styles.listItemText}
          />
        </ListItem>
      );
    }
    return stores.map((store) => {
      return (
        <StoreListItem
          key={store}
          store={store}
          profileSelectedDataset={profileSelectedDataset}
        />
      );
    });
  };
  return (
    <>
      <div style={{ display: 'grid', gridTemplateColumns: '7fr 2fr 1fr' }}>
        <ListItem>
          <ListItemText>MRI System</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Role</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText sx={{ textAlign: 'center' }}>
            Status Notifications
          </ListItemText>
        </ListItem>
      </div>
      <List sx={styles.list}>{render()}</List>
    </>
  );
};

const styles = {
  list: {
    maxHeight: '25vh',
    overflow: 'auto',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    margin: 'auto',
  },
  listItemText: {
    textAlign: 'center',
  },
};

RolesTable.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  profileSelectedDataset: PropTypes.string,
  stores: PropTypes.arrayOf(PropTypes.string),
};

RolesTable.defaultProps = {
  isLoading: false,
  isError: false,
  profileSelectedDataset: '',
  stores: [],
};

export default RolesTable;
