import React from 'react';
import { Button } from '@mui/material';
import { useFilters } from 'modules/filters/context/filtersContext';
import useAdminProfile from 'modules/admin/useAdminProfile';
import { ROOT_ADMIN } from 'utils/constants';

const MriSuperAdminButton = () => {
  const { data: { authorizationLevel = '' } = {} } = useAdminProfile();
  const { mriSuperAdminMode, setMriSuperAdminMode } = useFilters();

  const onMriSuperAdminButtonClick = () => {
    setMriSuperAdminMode(!mriSuperAdminMode);
  };

  if (authorizationLevel === ROOT_ADMIN) {
    return (
      <Button
        sx={styles.button}
        variant={mriSuperAdminMode ? 'contained' : 'outlined'}
        color="success"
        onClick={onMriSuperAdminButtonClick}
      >
        Manage MRI Super Admin
      </Button>
    );
  }

  return null;
};

const styles = {
  button: { height: '4em', minWidth: '10em', margin: '0 1em 3em 0' },
};

export default MriSuperAdminButton;
