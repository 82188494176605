export const INCREASE_PAGE = 'increase_page';
export const DECREASE_PAGE = 'decrease_page';
export const RESET_FILTERS = 'reset_filters';
export const UPDATE_SEARCH_TYPE = 'update_search_type';
export const UPDATE_SEARCH_VALUE = 'update_search_value';
export const SET_SELECTED_DATASET = 'set_selected_dataset';
export const SET_SELECTED_DICOM_STORE = 'set_selected_dicom_store';
export const RESET_PAGE = 'reset_page';
export const SET_PROFILE_SELECTED_DATASET = 'set_profile_selected_dataset';
export const SET_MRI_SUPER_ADMIN_MODE = 'set_mri_super_admin_mode';
