/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Typography } from '@mui/material';
import useAdminProfile from 'modules/admin/useAdminProfile';
import { useFilters } from 'modules/filters/context/filtersContext';
import { ADMIN_ACCOUNT_TYPE, USER_ACCOUNT_TYPE } from 'utils/constants';
import { useUser } from 'modules/user/context/userContext';

const DatasetSelect = () => {
  const { data: { allDatasets = [], datasetAuthorization = [] } = {} } =
    useAdminProfile();
  const { profileSelectedDataset, setProfileSelectedDataset, selectedDataset } =
    useFilters();
  const { accountType } = useUser();
  const handleOnDatasetSelectChange = ({ target: { value } }) => {
    setProfileSelectedDataset(value);
  };
  const [dropDownOptions, setDropDownOptions] = useState([]);

  useEffect(() => {
    let defaultSelectedDataset;
    if (
      selectedDataset &&
      (accountType === USER_ACCOUNT_TYPE ||
        datasetAuthorization.includes(selectedDataset))
    ) {
      defaultSelectedDataset = selectedDataset;
    } else if (accountType === ADMIN_ACCOUNT_TYPE) {
      [defaultSelectedDataset] = datasetAuthorization;
    } else {
      [defaultSelectedDataset] = allDatasets;
    }

    setProfileSelectedDataset(defaultSelectedDataset);
  }, [
    accountType,
    allDatasets,
    datasetAuthorization,
    selectedDataset,
    setProfileSelectedDataset,
  ]);

  useEffect(() => {
    if (accountType === USER_ACCOUNT_TYPE) {
      setDropDownOptions(allDatasets);
    } else {
      setDropDownOptions(datasetAuthorization);
    }
  }, [accountType, allDatasets, datasetAuthorization]);

  if (dropDownOptions.length > 1) {
    return (
      <>
        <Typography style={styles.typography}>Institution:</Typography>
        <Select
          value={profileSelectedDataset}
          onChange={handleOnDatasetSelectChange}
          autoWidth
          defaultValue="Select"
        >
          {dropDownOptions.map((set) => (
            <MenuItem key={set} value={set}>
              {set}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  }
  return <Typography>Institution: {profileSelectedDataset}</Typography>;
};

const styles = {
  typography: { display: 'inline', marginRight: '1em' },
};

export default DatasetSelect;
