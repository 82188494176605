/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Checkbox, Typography } from '@mui/material';
import useAdminProfile from 'modules/admin/useAdminProfile';
import { ADMIN_ACCOUNT_TYPE } from 'utils/constants';
import { useUser } from 'modules/user/context/userContext';
import isSuperAdminOrAbove from 'modules/auth/helpers';

const DatasetAdminCheckBox = ({
  selectedDataset,
  isDatasetAdmin,
  setIsDatasetAdmin,
}) => {
  const {
    accountType,
    datasetAuthorization,
    datasetAuthorizationChanges,
    setDatasetAuthorizationChanges,
  } = useUser();
  const { data: { authorizationLevel = '' } = {} } = useAdminProfile();

  useEffect(() => {
    if (accountType === ADMIN_ACCOUNT_TYPE) {
      let isAdmin = false;
      const roleChange = datasetAuthorizationChanges[selectedDataset];
      if (roleChange) {
        isAdmin = roleChange === 'added';
      } else if (datasetAuthorization.includes(selectedDataset)) {
        isAdmin = true;
      }
      setIsDatasetAdmin(isAdmin);
    }
  }, [
    datasetAuthorization,
    datasetAuthorizationChanges,
    selectedDataset,
    setIsDatasetAdmin,
    accountType,
  ]);

  const handleCheckBoxChange = () => {
    const change = !isDatasetAdmin ? 'added' : 'removed';
    const changesCopy = { ...datasetAuthorizationChanges };
    if (datasetAuthorizationChanges[selectedDataset]) {
      delete changesCopy[selectedDataset];
    } else {
      changesCopy[selectedDataset] = change;
    }
    setDatasetAuthorizationChanges(changesCopy);
  };

  if (
    accountType === ADMIN_ACCOUNT_TYPE &&
    (isSuperAdminOrAbove(authorizationLevel) || isDatasetAdmin)
  ) {
    return (
      <>
        <Typography sx={{ display: 'inline', marginLeft: '2em' }}>
          Institution admin:
        </Typography>
        <Checkbox
          size="large"
          checked={isDatasetAdmin}
          disabled={!isSuperAdminOrAbove(authorizationLevel)}
          onChange={handleCheckBoxChange}
        />
      </>
    );
  }
  return null;
};

export default DatasetAdminCheckBox;
