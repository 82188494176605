import React from 'react';

const ErrorPage = () => {
  return (
    <div
      style={{
        minHeight: 'calc(100vh - 10rem)',
        padding: '5rem 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <section>
        <h3 style={{ textTransform: 'none', marginBottom: '2rem' }}>
          Sorry, something went wrong. Try Again.
        </h3>
      </section>
    </div>
  );
};

export default ErrorPage;
