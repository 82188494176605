import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { DATASET_ADMIN } from 'utils/constants';
import { useModal } from 'modules/modal/context/modalContext';
import useAdminProfile from 'modules/admin/useAdminProfile';
import { useFilters } from 'modules/filters/context/filtersContext';
import isSuperAdminOrAbove from 'modules/auth/helpers';

const NewMenuButton = () => {
  const {
    setIsNewDatasetModalVisible,
    setIsNewDataStoreModalVisible,
    setIsNewUserModalVisible,
    setIsNewMRISuperAdminModalVisible,
  } = useModal();

  const { mriSuperAdminMode } = useFilters();
  const { data: { authorizationLevel = '' } = {} } = useAdminProfile();
  const [anchorEl, setAnchorEl] = useState(null);

  const isDropDownMenuUsed =
    isSuperAdminOrAbove(authorizationLevel) ||
    authorizationLevel === DATASET_ADMIN;

  const handleNewMenuClick = ({ currentTarget }) => {
    if (isDropDownMenuUsed) {
      setAnchorEl(currentTarget);
    } else setIsNewUserModalVisible(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNewUserClick = () => {
    if (mriSuperAdminMode) {
      setIsNewMRISuperAdminModalVisible(true);
    } else {
      setIsNewUserModalVisible(true);
    }

    handleMenuClose();
  };

  const renderMenu = () => {
    if (isDropDownMenuUsed) {
      return (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {isSuperAdminOrAbove(authorizationLevel) && (
            <MenuItem
              onClick={() => {
                setIsNewDatasetModalVisible(true);
                handleMenuClose();
              }}
            >
              Institution
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setIsNewDataStoreModalVisible(true);
              handleMenuClose();
            }}
          >
            MRI System
          </MenuItem>
          <MenuItem onClick={handleNewUserClick}>
            {mriSuperAdminMode ? 'MRI Super Admin' : 'User'}
          </MenuItem>
        </Menu>
      );
    }
    return null;
  };

  if (authorizationLevel)
    return (
      <>
        <Button
          sx={styles.button}
          variant="outlined"
          color="success"
          onClick={handleNewMenuClick}
        >
          {isDropDownMenuUsed ? 'New' : 'New User'}
        </Button>
        {renderMenu()}
      </>
    );
  return null;
};

const styles = {
  button: { height: '4em', minWidth: '10em', marginBottom: '3em' },
};

export default NewMenuButton;
