import { Box, TextField, Typography } from '@mui/material';
import { Loading, Modal } from 'modules/common/components';
import EvenlySpacedButtons from 'modules/common/components/Buttons/EvenlySpacedButtons';
import { useModal } from 'modules/modal/context/modalContext';
import useMutationHooks from 'modules/user/hooks/useMutationHooks';
import React, { useState, useEffect, useCallback } from 'react';
import {
  EMAIL_VALIDATION_REGEX,
  MRI_SUPER_ADMIN_ACCOUNT_TYPE,
} from 'utils/constants';

const initialState = {
  name: '',
  email: '',
  isNameError: false,
  isEmailError: false,
  confirming: false,
};

const NewMriSuperAdmin = () => {
  const { setIsNewMRISuperAdminModalVisible, isNewMriSuperAdminModalVisible } =
    useModal();

  const [{ name, email, isNameError, isEmailError, confirming }, setState] =
    useState(initialState);

  const { createNewMriSuperAdminMutation } = useMutationHooks();
  const { isSuccess, isLoading, reset } = createNewMriSuperAdminMutation;

  const handleOnCloseModal = useCallback(() => {
    setIsNewMRISuperAdminModalVisible(false);
    setState(initialState);
  }, [setIsNewMRISuperAdminModalVisible]);

  useEffect(() => {
    if (isSuccess) {
      handleOnCloseModal();
      reset();
    }
  }, [handleOnCloseModal, isSuccess, reset]);

  if (!isNewMriSuperAdminModalVisible) {
    return null;
  }

  const handleNameChange = ({ target: { value } }) => {
    setState((state) => {
      return { ...state, name: value };
    });
  };

  const handleEmailChange = ({ target: { value } }) => {
    setState((state) => {
      return { ...state, email: value };
    });
  };

  const onCancelClick = () => {
    if (!confirming) {
      handleOnCloseModal();
    } else {
      setState((state) => {
        return {
          ...state,
          confirming: false,
        };
      });
    }
  };

  const onConfirmClick = () => {
    if (!confirming) {
      const nameError = !name.trim().length;

      const emailValidationRegex = new RegExp(EMAIL_VALIDATION_REGEX);
      const emailError = !emailValidationRegex.test(email);

      setState((state) => {
        return { ...state, isNameError: nameError, isEmailError: emailError };
      });

      if (nameError || emailError) {
        return;
      }

      setState((state) => {
        return {
          ...state,
          confirming: true,
        };
      });
    } else {
      createNewMriSuperAdminMutation.mutate({
        name,
        email,
        accountType: MRI_SUPER_ADMIN_ACCOUNT_TYPE,
      });
    }
  };

  const renderInfo = () => {
    if (confirming) {
      return (
        <>
          <Typography variant="h6">Name:</Typography>
          <Typography
            variant="subtitle1"
            fontSize="18px"
            sx={styles.nameTypography}
          >
            {name}
          </Typography>
          <Typography variant="h6">Email:</Typography>
          <Typography
            variant="subtitle1"
            fontSize="18px"
            sx={styles.emailTypography}
          >
            {email}
          </Typography>
          <br />
          <Typography>
            Are you sure you want to add the user as a MRI Super Admin? This
            will allow the user to run calibrations on any MRI system.
          </Typography>
        </>
      );
    }

    return (
      <>
        <TextField
          value={name}
          required
          error={isNameError}
          type="text"
          label="Name"
          onChange={handleNameChange}
          sx={styles.textField}
        />
        <br />
        <TextField
          value={email}
          required
          error={isEmailError}
          type="email"
          label="Email"
          sx={styles.textField}
          onChange={handleEmailChange}
        />
      </>
    );
  };

  if (isLoading) {
    return (
      <Modal
        title="Creating New MRI Super Admin..."
        isOpen={isNewMriSuperAdminModalVisible}
      >
        <Loading />
      </Modal>
    );
  }

  return (
    <Modal
      title="New MRI Super Admin"
      isOpen={isNewMriSuperAdminModalVisible}
      onClose={handleOnCloseModal}
    >
      <Box sx={styles.box}>{renderInfo()}</Box>
      <EvenlySpacedButtons
        leftBtnText="Cancel"
        leftBtnOnClick={onCancelClick}
        leftBtnColor="inherit"
        rightBtnText={confirming ? 'Confirm' : 'Save'}
        rightBtnOnClick={onConfirmClick}
      />
    </Modal>
  );
};

const styles = {
  box: { margin: '1em 0 0 .5em' },
  textField: { flex: 1, margin: '0 0.5em 0.5em 0' },
};

export default NewMriSuperAdmin;
