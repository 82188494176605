import axios from 'axios';
import { useFilters } from 'modules/filters/context/filtersContext';
import { useQuery } from 'react-query';
import { ROWS_PER_PAGE, USER_URL } from 'utils/constants';

export const fetchAllUsers = async (
  type,
  value,
  dataset,
  dataStore,
  page,
  mriSuperAdminMode
) => {
  const response = await axios.get(USER_URL, {
    params: {
      type,
      value,
      dataset,
      dataStore,
      perPage: ROWS_PER_PAGE,
      page,
      mriSuperAdminMode,
    },
  });
  return response.data;
};

const useFetchAllUsers = () => {
  const {
    searchValue,
    searchType,
    selectedDataset,
    selectedDataStore,
    page,
    mriSuperAdminMode,
  } = useFilters();

  return useQuery(
    [
      'fetchAllUsers',
      searchType,
      searchValue,
      selectedDataset,
      selectedDataStore,
      page,
      mriSuperAdminMode,
    ],
    () =>
      fetchAllUsers(
        searchType,
        searchValue,
        selectedDataset,
        selectedDataStore,
        page,
        mriSuperAdminMode
      )
  );
};

export default useFetchAllUsers;
