import React from 'react';
import { Box, AppBar, Toolbar, Typography } from '@mui/material';
import { PromaxoBlue } from 'styles/Main';
import NavbarAuthButtons from 'modules/common/components/Navbar/NavAuthButtons';

const Navbar = () => {
  return (
    <Box sx={{ marginBottom: '10em' }}>
      <AppBar sx={PromaxoBlue}>
        <Toolbar>
          <Box
            component="img"
            sx={{
              height: '2em',
              marginRight: '.5em',
            }}
            alt="Promaxo Logo"
            src="promaxoLogo.png"
          />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Viewer Admin
          </Typography>
          <NavbarAuthButtons />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
