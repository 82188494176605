import {
  ADD_ERROR_ALERT,
  ADD_INFO_ALERT,
  ADD_SUCCESS_ALERT,
  REMOVE_ERROR_ALERT,
  REMOVE_INFO_ALERT,
  REMOVE_SUCCESS_ALERT,
} from 'modules/alert/context/actions';

const alertReducer = (state, { type, payload }) => {
  switch (type) {
    case ADD_ERROR_ALERT: {
      const { errorAlerts } = state;
      const alerts = [...errorAlerts];
      alerts.push(payload);
      return { ...state, errorAlerts: alerts };
    }
    case ADD_INFO_ALERT: {
      const { infoAlerts } = state;
      const alerts = [...infoAlerts];
      alerts.push(payload);
      return { ...state, infoAlerts: alerts };
    }
    case ADD_SUCCESS_ALERT: {
      const { successAlerts } = state;
      const alerts = [...successAlerts];
      alerts.push(payload);
      return { ...state, successAlerts: alerts };
    }
    case REMOVE_ERROR_ALERT: {
      const { errorAlerts } = state;
      const [, ...rest] = errorAlerts;
      return { ...state, errorAlerts: rest };
    }
    case REMOVE_INFO_ALERT: {
      const { infoAlerts } = state;
      const [, ...rest] = infoAlerts;
      return { ...state, infoAlerts: rest };
    }
    case REMOVE_SUCCESS_ALERT: {
      const { successAlerts } = state;
      const [, ...rest] = successAlerts;
      return { ...state, successAlerts: rest };
    }
    default:
      return state;
  }
};

export default alertReducer;
