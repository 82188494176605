import React from 'react';
import { Button } from '@mui/material';
import useAuth from 'modules/auth/hooks/UseAuth';

const NavbarAuthButtons = () => {
  const { loginWithRedirect, logout, isAuthenticated, isLoading, accessToken } =
    useAuth();

  const handleLogoutClick = () => {
    logout({ returnTo: window.location.origin });
  };

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <Button onClick={loginWithRedirect} color="inherit">
        Log in
      </Button>
    );
  }

  if (accessToken) {
    return (
      <Button onClick={handleLogoutClick} data-cy="logout" color="inherit">
        Logout
      </Button>
    );
  }
  return null;
};

export default NavbarAuthButtons;
