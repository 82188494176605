import React from 'react';
import { TablePagination as Pagination } from '@mui/material';
import { useFilters } from 'modules/filters/context/filtersContext';
import { ROWS_PER_PAGE } from 'utils/constants';

// eslint-disable-next-line react/prop-types
const TablePagination = ({ totalUsers }) => {
  const { page, increasePage, decreasePage } = useFilters();
  return (
    <tfoot>
      <tr>
        <Pagination
          labelRowsPerPage={null}
          rowsPerPageOptions={[-1]}
          page={page}
          rowsPerPage={ROWS_PER_PAGE}
          count={totalUsers}
          onPageChange={() => {}}
          backIconButtonProps={{
            onClick: decreasePage,
          }}
          nextIconButtonProps={{
            onClick: increasePage,
          }}
        />
      </tr>
    </tfoot>
  );
};

export default TablePagination;
