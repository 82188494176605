import axios from 'axios';
import { USER_URL, MRI_SUPER_ADMIN_URL } from 'utils/constants';
import { useMutation, useQueryClient } from 'react-query';
import { useUser } from 'modules/user/context/userContext';
import { useAlert } from 'modules/alert/context/alertContext';

const useMutationHooks = () => {
  const queryClient = useQueryClient();
  const { resetUser } = useUser();
  const { addErrorAlert, addSuccessAlert } = useAlert();

  const deleteUserMutation = useMutation(
    (id) => {
      return axios.delete(`${USER_URL}/${id}`);
    },
    {
      onSuccess: () => {
        addSuccessAlert('Account deleted');
        queryClient.invalidateQueries('fetchAllUsers');
        deleteUserMutation.reset();
        resetUser();
      },
      onError: (error) => {
        addErrorAlert(error.response.data.message);
        deleteUserMutation.reset();
      },
    }
  );

  const deleteMriSuperAdminMutation = useMutation(
    (id) => {
      return axios.delete(`${MRI_SUPER_ADMIN_URL}/${id}`);
    },
    {
      onSuccess: () => {
        addSuccessAlert('Account deleted');
        queryClient.invalidateQueries('fetchAllUsers');
        deleteMriSuperAdminMutation.reset();
        resetUser();
      },
      onError: (error) => {
        addErrorAlert(error.response.data.message);
        deleteMriSuperAdminMutation.reset();
      },
    }
  );

  const createUserMutation = useMutation(
    ({ name, email, roleChanges, accountType, notificationChanges }) => {
      return axios.post(USER_URL, {
        name,
        email,
        accountType,
        roleChanges,
        notificationChanges,
      });
    },
    {
      onSuccess: () => {
        addSuccessAlert('Account created');
        queryClient.invalidateQueries('fetchAllUsers');
      },
      onError: (error) => {
        addErrorAlert(error.response.data.message);
        createUserMutation.reset();
      },
    }
  );

  const createNewMriSuperAdminMutation = useMutation(
    ({ name, email, accountType }) => {
      return axios.post(MRI_SUPER_ADMIN_URL, { name, email, accountType });
    },
    {
      onSuccess: () => {
        addSuccessAlert('Account created');
        queryClient.invalidateQueries('fetchAllUsers');
      },
      onError: (error) => {
        addErrorAlert(error.response.data.message);
        createNewMriSuperAdminMutation.reset();
      },
    }
  );

  const userRolesMutation = useMutation(
    ({ userId, roleChanges, accountType, notificationChanges }) => {
      return axios.patch(USER_URL, {
        userId,
        roleChanges,
        accountType,
        notificationChanges,
      });
    },
    {
      onSuccess: (_, { userId }) => {
        addSuccessAlert('Account updated');
        queryClient.invalidateQueries('fetchAllUsers');
        userRolesMutation.reset();
        resetUser();
      },
      onError: (error) => {
        addErrorAlert(error.response.data.message);
        userRolesMutation.reset();
      },
    }
  );
  return {
    deleteUserMutation,
    userRolesMutation,
    createUserMutation,
    createNewMriSuperAdminMutation,
    deleteMriSuperAdminMutation,
  };
};

export default useMutationHooks;
