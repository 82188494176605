import {
  SET_IS_NEW_DATASET_MODAL_VISIBLE,
  SET_IS_NEW_DATASTORE_MODAL_VISIBLE,
  SET_IS_EXISTING_USER_MODAL_VISIBLE,
  SET_IS_NEW_USER_MODAL_VISIBLE,
  SET_IS_SUMMARY_MODAL_VISIBLE,
  SET_IS_WARNING_MODAL_VISIBLE,
  SET_IS_MRI_SUPER_ADMIN_MODAL_VISIBLE,
  SET_IS_NEW_MRI_SUPER_ADMIN_MODAL_VISIBLE,
} from 'modules/modal/context/actions';

const modalReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_IS_NEW_DATASET_MODAL_VISIBLE:
      return {
        ...state,
        isNewDatasetModalVisible: payload,
      };
    case SET_IS_NEW_DATASTORE_MODAL_VISIBLE:
      return {
        ...state,
        isNewDataStoreModalVisible: payload,
      };
    case SET_IS_EXISTING_USER_MODAL_VISIBLE:
      return {
        ...state,
        isExistingUserModalVisible: payload,
      };
    case SET_IS_NEW_USER_MODAL_VISIBLE:
      return {
        ...state,
        isNewUserModalVisible: payload,
      };
    case SET_IS_SUMMARY_MODAL_VISIBLE:
      return {
        ...state,
        isSummaryModalVisible: payload,
      };
    case SET_IS_WARNING_MODAL_VISIBLE: {
      return {
        ...state,
        isWarningModalVisible: payload,
      };
    }

    case SET_IS_MRI_SUPER_ADMIN_MODAL_VISIBLE: {
      return {
        ...state,
        isMriSuperAdminModalVisible: payload,
      };
    }

    case SET_IS_NEW_MRI_SUPER_ADMIN_MODAL_VISIBLE: {
      return { ...state, isNewMriSuperAdminModalVisible: payload };
    }
    default:
      return state;
  }
};

export default modalReducer;
