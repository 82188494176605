import React, { useEffect, useState } from 'react';
import { Table, TableBody } from '@mui/material';
import { TableHeader, TablePagination } from 'modules/common/components/Table';
import useFetchAllUsers from 'modules/user/hooks/useFetchAllUsers';
import CustomizableRow from 'modules/common/components/Table/CustomizableRow';
import UserRow from 'modules/user/components/UserRow';

const UsersTable = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const {
    isLoading,
    isError,
    data: { users = [], total, length = 0 } = {},
  } = useFetchAllUsers();

  useEffect(() => {
    if (total !== undefined) {
      setTotalUsers(total);
    }
  }, [total]);

  let headers = ['Users'];
  if (length && !isError) {
    headers = ['', 'Name', 'Email', 'Account Type'];
  }

  const renderTableBody = () => {
    if (isError) {
      return <CustomizableRow rowType="error" />;
    }
    if (isLoading) {
      return <CustomizableRow rowType="loading" />;
    }
    if (length === 0) {
      return <CustomizableRow rowType="empty" dataType="users" />;
    }
    return users.map((user) => <UserRow key={user.user_id} user={user} />);
  };

  return (
    <Table sx={styles.table} size="md">
      <TableHeader headers={headers} />
      <TableBody>{renderTableBody()}</TableBody>
      <TablePagination totalUsers={totalUsers} />
    </Table>
  );
};

const styles = {
  div: { maxWidth: '60rem', margin: '0 auto' },
  table: {
    boxShadow:
      'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
  },
};

export default UsersTable;
