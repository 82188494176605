import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText, Divider, Checkbox } from '@mui/material';
import SelectRoles from 'modules/user/components/SelectRoles';
import { useUser } from 'modules/user/context/userContext';
import { getItemColor, getRoleDifference } from 'modules/user/helpers';
import { NONE } from 'utils/constants';

const StoreListItem = ({ profileSelectedDataset, store }) => {
  const {
    roles,
    updatedRoles,
    handleRoleChange,
    updatedStatusNotifications,
    handleNotificationChange,
  } = useUser();

  const roleDifference = getRoleDifference(
    updatedRoles,
    roles,
    profileSelectedDataset,
    store
  );

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    handleNotificationChange(profileSelectedDataset, store, checked);
  };

  const renderCheckbox = () => {
    const disabled = roleDifference.newRole === NONE;

    const checked = Boolean(
      updatedStatusNotifications[profileSelectedDataset]?.includes(store)
    );

    return (
      <Checkbox
        onClick={handleCheckboxChange}
        checked={checked}
        disabled={disabled}
      />
    );
  };

  const handleSelectRoleChange = (event) => {
    const newRole = event.target.value;
    handleRoleChange(profileSelectedDataset, store, roles, newRole);
  };
  return (
    <div key={store}>
      <ListItem
        sx={{ backgroundColor: getItemColor(roleDifference), ...styles.grid }}
      >
        <ListItemText primary={store} />
        <SelectRoles
          userRole={roleDifference.newRole}
          handleSelectRoleChange={handleSelectRoleChange}
        />
        {renderCheckbox()}
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </div>
  );
};

const styles = {
  grid: {
    display: 'grid',
    gridTemplateColumns: '5fr 2fr 1fr',
  },
};

StoreListItem.propTypes = {
  profileSelectedDataset: PropTypes.string,
  store: PropTypes.string,
};

StoreListItem.defaultProps = {
  profileSelectedDataset: '',
  store: '',
};

export default StoreListItem;
