// General
export const AUTH_UNAUTHORIZED_MSG = 'Forbidden: Unauthorized to access';
export const ACCESS_DENIED = 'access_denied';
export const ROWS_PER_PAGE = 10;
export const ENTER_KEY = 'Enter';
export const DICOM_STORE = 'dicom_store';
export const DATASET = 'dataset';
export const LOADING = 'loading';
export const EMPTY = 'empty';
export const ERROR = 'error';
export const LOGIN_ERROR_MESSAGE = 'login_error_message';
export const ADMIN_ACCOUNT_TYPE = 'admin_account_type';
export const USER_ACCOUNT_TYPE = 'user_account_type';
export const MRI_SUPER_ADMIN_ACCOUNT_TYPE = 'mri_super_admin_account_type';
export const ADDED = 'added';
export const REMOVED = 'removed';
export const STATUS_NOTIFICATION_CHANGES = 'status_notification_changes';
export const DATASET_CHANGES = 'dataset_changes';
export const EMAIL_VALIDATION_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

// Roles
export const NONE = 'none';
export const ADMIN = 'admin';
export const VIEWER = 'viewer';
export const USER = 'user';
export const ROOT_ADMIN = 'root_admin';
export const SUPER_ADMIN = 'super_admin';
export const DATASET_ADMIN = 'dataset_admin';
export const STORE_ADMIN = 'store_admin';
export const USER_ROLES = [NONE, VIEWER, USER];
export const ADMIN_ROLES = [NONE, ADMIN];

// URLs
export const BASE_URL = `${process.env.REACT_APP_SERVER_URL}/api`;
export const ADMIN_URL = `${BASE_URL}/admin`;
export const DATASET_URL = `${ADMIN_URL}/dataset`;
export const DATA_STORES_URL = `${ADMIN_URL}/datastore`;
export const USER_URL = `${BASE_URL}/user`;
export const MRI_SUPER_ADMIN_URL = `${USER_URL}/mriSuperAdmin`;
