import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import modalReducer from 'modules/modal/context/modalReducer';
import {
  SET_IS_NEW_DATASET_MODAL_VISIBLE,
  SET_IS_NEW_DATASTORE_MODAL_VISIBLE,
  SET_IS_EXISTING_USER_MODAL_VISIBLE,
  SET_IS_NEW_USER_MODAL_VISIBLE,
  SET_IS_SUMMARY_MODAL_VISIBLE,
  SET_IS_WARNING_MODAL_VISIBLE,
  SET_IS_MRI_SUPER_ADMIN_MODAL_VISIBLE,
  SET_IS_NEW_MRI_SUPER_ADMIN_MODAL_VISIBLE,
} from 'modules/modal/context/actions';
import { useUser } from 'modules/user/context/userContext';
import { MRI_SUPER_ADMIN_ACCOUNT_TYPE } from 'utils/constants';

const initialState = {
  isNewDatasetModalVisible: false,
  isNewDataStoreModalVisible: false,
  isExistingUserModalVisible: false,
  isMriSuperAdminModalVisible: false,
  isNewMriSuperAdminModalVisible: false,
  isNewUserModalVisible: false,
  isSummaryModalVisible: false,
  isWarningModalVisible: false,
};
const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);
  const { userId, accountType } = useUser();

  const setIsExistingUserModalVisible = useCallback((bool) => {
    dispatch({ type: SET_IS_EXISTING_USER_MODAL_VISIBLE, payload: bool });
  }, []);

  const setIsMriSuperAdminModalVisible = useCallback((bool) => {
    dispatch({ type: SET_IS_MRI_SUPER_ADMIN_MODAL_VISIBLE, payload: bool });
  }, []);

  useEffect(() => {
    if (userId) {
      if (accountType === MRI_SUPER_ADMIN_ACCOUNT_TYPE) {
        setIsMriSuperAdminModalVisible(true);
      } else {
        setIsExistingUserModalVisible(true);
      }
    } else {
      setIsExistingUserModalVisible(false);
      setIsMriSuperAdminModalVisible(false);
    }
  }, [
    userId,
    setIsExistingUserModalVisible,
    setIsMriSuperAdminModalVisible,
    accountType,
  ]);

  const setIsNewDatasetModalVisible = useCallback((bool) => {
    dispatch({ type: SET_IS_NEW_DATASET_MODAL_VISIBLE, payload: bool });
  }, []);

  const setIsNewDataStoreModalVisible = useCallback((bool) => {
    dispatch({ type: SET_IS_NEW_DATASTORE_MODAL_VISIBLE, payload: bool });
  }, []);

  const setIsNewUserModalVisible = (bool) => {
    dispatch({ type: SET_IS_NEW_USER_MODAL_VISIBLE, payload: bool });
  };

  const setIsSummaryModalVisible = (bool) => {
    dispatch({ type: SET_IS_SUMMARY_MODAL_VISIBLE, payload: bool });
  };

  const setIsWarningModalVisible = (bool) => {
    dispatch({ type: SET_IS_WARNING_MODAL_VISIBLE, payload: bool });
  };

  const setIsNewMRISuperAdminModalVisible = (bool) => {
    dispatch({ type: SET_IS_NEW_MRI_SUPER_ADMIN_MODAL_VISIBLE, payload: bool });
  };

  const value = useMemo(() => {
    return {
      ...state,
      setIsNewDatasetModalVisible,
      setIsNewDataStoreModalVisible,
      setIsExistingUserModalVisible,
      setIsNewUserModalVisible,
      setIsSummaryModalVisible,
      setIsWarningModalVisible,
      setIsMriSuperAdminModalVisible,
      setIsNewMRISuperAdminModalVisible,
    };
  }, [
    setIsExistingUserModalVisible,
    setIsNewDataStoreModalVisible,
    setIsNewDatasetModalVisible,
    setIsMriSuperAdminModalVisible,
    state,
  ]);

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node,
};

ModalProvider.defaultProps = {
  children: {},
};

export const useModal = () => {
  return useContext(ModalContext);
};
