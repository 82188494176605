import React, { useState } from 'react';
import useFetchedAdminDataStores from 'modules/admin/useFetchedAdminDataStores';
import DatasetSelect from 'modules/user/components/DatasetSelect';
import { useFilters } from 'modules/filters/context/filtersContext';
import DatasetAdminCheckBox from './DatasetAdminCheckbox';
import RolesTable from './RolesTable';

const AllUserRoles = () => {
  const { profileSelectedDataset } = useFilters();
  const {
    isLoading,
    isError,
    data: adminDataStoresInSelectedDataset,
  } = useFetchedAdminDataStores(profileSelectedDataset);
  const [isDatasetAdmin, setIsDatasetAdmin] = useState(false);

  return (
    <>
      <DatasetSelect />
      <DatasetAdminCheckBox
        selectedDataset={profileSelectedDataset}
        isDatasetAdmin={isDatasetAdmin}
        setIsDatasetAdmin={setIsDatasetAdmin}
      />
      {!isDatasetAdmin && (
        <RolesTable
          isError={isError}
          isLoading={isLoading}
          profileSelectedDataset={profileSelectedDataset}
          stores={adminDataStoresInSelectedDataset}
        />
      )}
    </>
  );
};

export default AllUserRoles;
