import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell } from '@mui/material';

const TableHeader = ({ headers }) => {
  const renderHeaders = () => {
    return headers.map((header) => (
      <TableCell key={header} sx={styles.headers}>
        {header}
      </TableCell>
    ));
  };
  return (
    <TableHead>
      <TableRow>{renderHeaders()}</TableRow>
    </TableHead>
  );
};

const styles = {
  headers: {
    fontWeight: 'bold',
  },
};

TableHeader.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
};

TableHeader.defaultProps = {
  headers: [],
};

export default TableHeader;
