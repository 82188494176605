import axios from 'axios';
import { useQuery } from 'react-query';
import { DATA_STORES_URL } from 'utils/constants';

const fetchAdminDataStores = async (dataset) => {
  if (dataset) {
    const response = await axios.get(DATA_STORES_URL, {
      params: { dataset },
    });

    return response.data;
  }

  return [];
};

const oneMinuteStaleTime = 60000;

const useFetchedAdminDataStores = (dataset) => {
  return useQuery(
    ['fetchAdminDataStores', dataset],
    () => fetchAdminDataStores(dataset),
    {
      refetchOnWindowFocus: false,
      staleTime: oneMinuteStaleTime,
    }
  );
};

export default useFetchedAdminDataStores;
