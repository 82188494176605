/* eslint-disable react/prop-types */
import React from 'react';
import { Grid } from '@mui/material';
import { Modal } from 'modules/common/components';
import EvenlySpacedButtons from 'modules/common/components/Buttons/EvenlySpacedButtons';
import UserBasicInformation from 'modules/user/components/UserBasicInformation';
import {
  USER_ACCOUNT_TYPE,
  NONE,
  DATASET_CHANGES,
  STATUS_NOTIFICATION_CHANGES,
  REMOVED,
} from 'utils/constants';
import { useUser } from 'modules/user/context/userContext';
import { parseChanges } from 'modules/user/helpers';
import useAdminProfile from 'modules/admin/useAdminProfile';
import SummaryTable from 'modules/user/components/SummaryTable';
import isSuperAdminOrAbove from 'modules/auth/helpers';

const Summary = ({ isOpen, onClose, onBtnClick, isUserBeingRemoved }) => {
  const {
    userId,
    accountType,
    roles,
    rolesChanges,
    datasetAuthorization,
    datasetAuthorizationChanges,
    statusNotificationChanges,
    statusNotifications,
  } = useUser();
  const {
    data: { authorizationLevel = '' },
  } = useAdminProfile();

  let changesToRoles = rolesChanges;
  let changesToDatasetAuthorization = datasetAuthorizationChanges;
  let changesToStatusNotifications = statusNotificationChanges;

  if (isUserBeingRemoved) {
    const rolesNegated = {};
    Object.keys(roles).forEach((key) => {
      const storeRoles = { ...roles[key] };
      Object.keys(storeRoles).forEach((role) => {
        storeRoles[role] = NONE;
      });
      rolesNegated[key] = storeRoles;
    });

    changesToRoles = parseChanges(roles, rolesNegated);

    if (isSuperAdminOrAbove(authorizationLevel)) {
      const negatedDatasetAuthorization = {};
      datasetAuthorization?.forEach((dataset) => {
        negatedDatasetAuthorization[dataset] = NONE;
      });
      changesToDatasetAuthorization = negatedDatasetAuthorization;
    }

    changesToStatusNotifications = [];

    Object.entries(statusNotifications).forEach(([dataset, dataStores]) => {
      dataStores.forEach((dataStore) => {
        changesToStatusNotifications.push({
          dataset,
          dataStore,
          change: REMOVED,
        });
      });
    });
  }

  const displayDatasetSummary =
    changesToDatasetAuthorization &&
    Object.keys(changesToDatasetAuthorization).length > 0;

  const displayDataStoreSummary = changesToRoles && changesToRoles.length > 0;

  const displayNotificationsChangesSummary =
    changesToStatusNotifications.length > 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Changes Summary">
      <UserBasicInformation />
      <Grid container direction="column">
        {displayDatasetSummary && (
          <SummaryTable
            title="Institution Authorization Changes"
            headers={['Institution', 'Change']}
            changes={Object.entries(changesToDatasetAuthorization).map(
              ([dataset, change]) => ({
                dataset,
                change,
              })
            )}
            type={DATASET_CHANGES}
          />
        )}
        {displayDataStoreSummary && (
          <SummaryTable
            title="MRI System Authorization Changes"
            headers={['Institution', 'MRI System', 'Old Role', 'New Role']}
            changes={changesToRoles}
          />
        )}
        {displayNotificationsChangesSummary && (
          <SummaryTable
            title="Status Notification Changes"
            headers={['Institution', 'MRI System', 'Change']}
            type={STATUS_NOTIFICATION_CHANGES}
            changes={changesToStatusNotifications}
          />
        )}
        {/* show this note for existing users only */}
        {userId && accountType === USER_ACCOUNT_TYPE && (
          <h5>
            Note: The user must log out and log back in on DICOM Image Viewer
            for changes to take effect!
          </h5>
        )}
        {/* For new users only */}
        {!userId && (
          <h5>
            Note: The user will receive an email to select a new password.
          </h5>
        )}
        <EvenlySpacedButtons
          leftBtnText="Cancel"
          leftBtnOnClick={onClose}
          leftBtnColor="inherit"
          rightBtnText="Save"
          rightBtnOnClick={onBtnClick}
        />
      </Grid>
    </Modal>
  );
};

export default Summary;
