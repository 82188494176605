import React, { createContext, useContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import alertReducer from 'modules/alert/context/alertReducer';
import {
  ADD_ERROR_ALERT,
  ADD_INFO_ALERT,
  ADD_SUCCESS_ALERT,
  REMOVE_ERROR_ALERT,
  REMOVE_INFO_ALERT,
  REMOVE_SUCCESS_ALERT,
} from 'modules/alert/context/actions';

const initialState = {
  errorAlerts: [],
  successAlerts: [],
  infoAlerts: [],
};
const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [state, dispatch] = useReducer(alertReducer, initialState);

  const removeErrorAlert = () => {
    dispatch({ type: REMOVE_ERROR_ALERT });
  };

  const removeInfoAlert = () => {
    dispatch({ type: REMOVE_INFO_ALERT });
  };

  const removeSuccessAlert = () => {
    dispatch({ type: REMOVE_SUCCESS_ALERT });
  };

  const value = useMemo(() => {
    const addErrorAlert = (error) => {
      const { errorAlerts } = state;
      if (!errorAlerts.includes(error)) {
        dispatch({ type: ADD_ERROR_ALERT, payload: error });
      }
    };

    const addInfoAlert = (info) => {
      const { infoAlerts } = state;
      if (!infoAlerts.includes(info)) {
        dispatch({ type: ADD_INFO_ALERT, payload: info });
      }
    };

    const addSuccessAlert = (success) => {
      const { successAlerts } = state;
      if (!successAlerts.includes(success)) {
        dispatch({ type: ADD_SUCCESS_ALERT, payload: success });
      }
    };
    return {
      ...state,
      addErrorAlert,
      addInfoAlert,
      addSuccessAlert,
      removeErrorAlert,
      removeInfoAlert,
      removeSuccessAlert,
    };
  }, [state]);

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.node,
};

AlertProvider.defaultProps = {
  children: {},
};

export const useAlert = () => {
  return useContext(AlertContext);
};
