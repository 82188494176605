import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, Typography } from '@mui/material';
import { TableHeader } from 'modules/common/components/Table';
import SummaryRow from 'modules/user/components/SummaryRow';

const SummaryTable = ({ title, changes, headers, type }) => {
  return (
    <>
      <Typography variant="h6" sx={{ marginTop: '1em', textAlign: 'center' }}>
        {title}
      </Typography>
      <Table>
        <TableHeader headers={headers} />
        <TableBody>
          {changes.map((summary) => (
            <SummaryRow
              key={JSON.stringify(summary)}
              summary={summary}
              type={type}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

SummaryTable.propTypes = {
  title: PropTypes.string,
  changes: PropTypes.arrayOf(PropTypes.shape()),
  headers: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
};

SummaryTable.defaultProps = {
  title: '',
  changes: [],
  headers: [],
  type: '',
};

export default SummaryTable;
