import React from 'react';
import { Avatar, Typography, Grid, Divider } from '@mui/material';
import { useUser } from 'modules/user/context/userContext';

const UserBasicInformation = () => {
  const { name, email, picture } = useUser();
  return (
    <Grid item sx={styles.box}>
      <div style={styles.div}>
        <div style={styles.avatarDiv}>
          <Avatar
            src={picture}
            alt="user picture"
            sx={{ width: '3em', height: '3em' }}
          />
        </div>
        <div style={styles.nameDiv}>
          <Typography variant="h6">Name:</Typography>
          <Typography
            variant="subtitle1"
            fontSize="18px"
            sx={styles.nameTypography}
          >
            {name}
          </Typography>
          <Typography variant="h6">Email:</Typography>
          <Typography
            variant="subtitle1"
            fontSize="18px"
            sx={styles.emailTypography}
          >
            {email}
          </Typography>
        </div>
      </div>
      <Divider sx={{ marginTop: '1em' }} />
    </Grid>
  );
};

const styles = {
  avatarDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: { margin: '.5em 0 0 .5em' },
  div: {
    display: 'grid',
    gridTemplateColumns: '10% auto',
    gridAutoFlow: 'column',
  },
  nameDiv: {
    marginLeft: '1em',
  },
};

export default UserBasicInformation;
