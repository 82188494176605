import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'modules/auth/hooks/UseAuth';
import Loading from 'modules/common/components/Loading';
import Login from 'modules/auth/components/Login';

const AuthWrapper = ({ children }) => {
  const { isLoading, isAuthenticated, accessToken, error } = useAuth();

  if (isLoading || (isAuthenticated && !accessToken)) {
    return <Loading />;
  }
  if (error || !isAuthenticated) {
    return <Login />;
  }

  return children;
};

AuthWrapper.propTypes = {
  children: PropTypes.node,
};

AuthWrapper.defaultProps = {
  children: () => {},
};

export default AuthWrapper;
