import React from 'react';
import { Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={styles.footer}
    >
      Copyright © &nbsp;
      <Link target="_blank" color="inherit" href="https://promaxo.com/">
        Promaxo
      </Link>
      &nbsp;{new Date().getFullYear()}
    </Typography>
  );
};

const styles = {
  footer: {
    mt: 3,
    mb: 4,
  },
};

export default Footer;
