import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Navbar, Footer } from 'modules/common/components';
import Dashboard from 'modules/dashboard/components/Dashboard';
import AuthWrapper from 'modules/auth/components/AuthWrapper';
import Alert from 'modules/alert/components/Alert';

const App = () => {
  return (
    <>
      <Navbar />
      <Alert />
      <AuthWrapper>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthWrapper>
      <Footer />
    </>
  );
};

export default App;
