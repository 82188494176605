import axios from 'axios';
import { DATASET_URL, DATA_STORES_URL } from 'utils/constants';
import { useMutation, useQueryClient } from 'react-query';
import { useAlert } from 'modules/alert/context/alertContext';

const useGCPMutationHooks = () => {
  const queryClient = useQueryClient();
  const { addErrorAlert, addSuccessAlert } = useAlert();

  const createDatasetAndWait = async (dataset, isDevDataset) => {
    await axios.post(DATASET_URL, { dataset, isDevDataset });
    // timeout is added because sometimes the query to fetch all datasets from GCP
    // isn't updated right away after creating a new dataset.
    await new Promise((res) => {
      setTimeout(res, 2500);
    });
  };

  const newDatasetMutation = useMutation(
    ({ dataset, isDevDataset }) => {
      return createDatasetAndWait(dataset, isDevDataset);
    },
    {
      onSuccess: (_, { dataset }) => {
        addSuccessAlert(`Institution "${dataset}" successfully created`);
        queryClient.invalidateQueries('fetchAdminProfile');
      },
      onError: (error) => {
        addErrorAlert(error.response.data.message);
        newDatasetMutation.reset();
      },
    }
  );

  const newDataStoreMutation = useMutation(
    ({ dataset, dataStore }) => {
      return axios.post(DATA_STORES_URL, {
        dataset,
        dataStore,
      });
    },
    {
      onSuccess: (_, { dataset, dataStore }) => {
        addSuccessAlert(
          `MRI system "${dataStore}" in institution "${dataset}" successfully created`
        );
        queryClient.invalidateQueries(['fetchAdminDataStores', dataset]);
      },
      onError: (error) => {
        addErrorAlert(error.response.data.message);
        newDataStoreMutation.reset();
      },
    }
  );

  return {
    newDatasetMutation,
    newDataStoreMutation,
  };
};

export default useGCPMutationHooks;
