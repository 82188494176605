import React from 'react';
import { Container, Box, Avatar, Typography, Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import useAuth from 'modules/auth/hooks/UseAuth';
import { PromaxoBlue } from 'styles/Main';

const Login = () => {
  const { loginWithRedirect } = useAuth();

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={styles.loginBox}>
        <Avatar sx={{ m: 1, bgcolor: PromaxoBlue.bgcolor }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={loginWithRedirect}
        >
          Sign In
        </Button>
      </Box>
    </Container>
  );
};

const styles = {
  loginBox: {
    marginTop: '10em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export default Login;
