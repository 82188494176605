import React, { useEffect, useState } from 'react';
import { Alert as AlertTemplate, Box, Collapse } from '@mui/material';
import { useAlert } from 'modules/alert/context/alertContext';
import { ERROR, SUCCESS, INFO } from 'modules/alert/utils/constants';

const initialState = { message: '', type: '' };

const Alert = () => {
  const [alert, setAlert] = useState(initialState);
  const {
    errorAlerts,
    successAlerts,
    infoAlerts,
    removeErrorAlert,
    removeInfoAlert,
    removeSuccessAlert,
  } = useAlert();
  const { message, type } = alert;
  useEffect(() => {
    if (!message) {
      if (errorAlerts.length) {
        setAlert({ message: errorAlerts[0], type: ERROR });
      } else if (successAlerts.length) {
        setAlert({ message: successAlerts[0], type: SUCCESS });
      } else if (infoAlerts.length) {
        setAlert({ message: infoAlerts[0], type: INFO });
      }
    }
    const timeoutId = setTimeout(() => {
      if (message) {
        if (type === ERROR) {
          removeErrorAlert();
        } else if (type === SUCCESS) {
          removeSuccessAlert();
        } else {
          removeInfoAlert();
        }
        setAlert(initialState);
      }
    }, 4000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    errorAlerts,
    infoAlerts,
    message,
    removeErrorAlert,
    removeInfoAlert,
    removeSuccessAlert,
    successAlerts,
    type,
  ]);

  if (!message) {
    return null;
  }

  return (
    <Box sx={styles.alert}>
      <Collapse in>
        <AlertTemplate severity={type} variant="filled">
          {message}
        </AlertTemplate>
      </Collapse>
    </Box>
  );
};

const styles = {
  alert: {
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, -110%)',
    width: '30%',
    zIndex: 2147483640,
  },
};

export default Alert;
