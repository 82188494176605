import {
  USER_ACCOUNT_TYPE,
  NONE,
  ADMIN,
  ADMIN_ACCOUNT_TYPE,
  REMOVED,
  ADDED,
} from 'utils/constants';

export const parseChanges = (originalRoles, updatedRoles) => {
  const changes = [];
  const keyLen = Object.keys(updatedRoles).length;
  if (keyLen) {
    const datasets = Object.keys(updatedRoles);
    datasets.forEach((dataset) => {
      const stores = Object.keys(updatedRoles[dataset]);
      stores.forEach((dataStore) => {
        let originalRole = NONE;
        if (
          originalRoles &&
          originalRoles[dataset] &&
          originalRoles[dataset][dataStore]
        ) {
          originalRole = originalRoles[dataset][dataStore];
        }
        const newRole = updatedRoles[dataset][dataStore] || originalRole;
        if (originalRole !== newRole) {
          changes.push({
            dataset,
            dataStore,
            originalRole,
            newRole,
          });
        }
      });
    });
  }
  return changes;
};

export const parseNotificationChanges = (
  originalNotifications,
  notificationChanges,
  dataset,
  dataStore,
  checked
) => {
  const existing =
    originalNotifications[dataset] &&
    originalNotifications[dataset].includes(dataStore);

  const removed = existing && !checked;
  const added = !existing && checked;

  const changes = notificationChanges.filter(
    (change) => !(change.dataset === dataset && change.dataStore === dataStore)
  );

  if (added || removed) {
    changes.push({ dataset, dataStore, change: removed ? REMOVED : ADDED });
  }

  return changes;
};

export const createChangeObject = (
  accountType,
  rolesChanges,
  datasetAuthorizationChanges,
  statusNotificationChanges
) => {
  const changes = {};
  if (accountType === USER_ACCOUNT_TYPE) {
    rolesChanges.forEach((change) => {
      const { dataset, dataStore, newRole } = change;
      if (!changes[dataset]) {
        changes[dataset] = {};
      }
      changes[dataset][dataStore] = newRole;
    });
  } else {
    const added = [];
    const removed = [];
    for (const [dataset, authChange] of Object.entries(
      datasetAuthorizationChanges
    )) {
      if (authChange === 'added') {
        added.push(dataset);
      } else {
        removed.push(dataset);
      }
    }
    changes.datasetAuthorizationChanges = { added, removed };
    changes.dataStoreAuthorizationChanges = {};

    rolesChanges.forEach((change) => {
      const { dataset, dataStore, originalRole, newRole } = change;
      if (!changes.dataStoreAuthorizationChanges[dataset]) {
        changes.dataStoreAuthorizationChanges[dataset] = {
          added: [],
          removed: [],
        };
      }
      if (originalRole === NONE && newRole === ADMIN) {
        changes.dataStoreAuthorizationChanges[dataset].added.push(dataStore);
      } else {
        changes.dataStoreAuthorizationChanges[dataset].removed.push(dataStore);
      }
    });
  }

  const notificationChanges = {};

  statusNotificationChanges.forEach((notificationChange) => {
    const { dataset, dataStore, change } = notificationChange;

    if (!notificationChanges[dataset]) {
      notificationChanges[dataset] = {
        [ADDED]: [],
        [REMOVED]: [],
      };
    }

    notificationChanges[dataset][change].push(dataStore);
  });

  return { changes, notificationChanges };
};

export const getRoleDifference = (
  updatedRoles,
  originalRoles,
  profileSelectedDataset,
  store
) => {
  let originalRole = NONE;
  if (
    originalRoles &&
    originalRoles[profileSelectedDataset] &&
    originalRoles[profileSelectedDataset][store]
  )
    originalRole = originalRoles[profileSelectedDataset][store];
  let newRole = originalRole;
  if (
    updatedRoles[profileSelectedDataset] &&
    updatedRoles[profileSelectedDataset][store]
  )
    newRole = updatedRoles[profileSelectedDataset][store];
  return { originalRole, newRole };
};

export const getItemColor = (roleChange) => {
  const { originalRole, newRole } = roleChange;
  // added new role
  if (originalRole === NONE && newRole !== NONE) {
    return '#CCFFCC';
  }
  // removed a role
  if (originalRole !== NONE && newRole === NONE) {
    return '#FFCCCC';
  }
  // changed a role
  if (originalRole !== newRole) {
    return '#FFFFCC';
  }
  return '';
};

export const getTitle = (accountType) => {
  let type = '';
  if (accountType === ADMIN_ACCOUNT_TYPE) type = 'Admin ';
  if (accountType === USER_ACCOUNT_TYPE) type = 'User ';
  return `${type}Profile`;
};
