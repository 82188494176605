export const SET_IS_EXISTING_USER_MODAL_VISIBLE =
  'set_is_existing_user_modal_visible';
export const SET_IS_NEW_USER_MODAL_VISIBLE = 'set_is_new_user_modal_visible';
export const SET_IS_NEW_DATASET_MODAL_VISIBLE =
  'set_is_new_dataset_modal_visible';
export const SET_IS_NEW_DATASTORE_MODAL_VISIBLE =
  'set_is_new_datastore_modal_visible';
export const SET_IS_SUMMARY_MODAL_VISIBLE = 'set_is_summary_modal_visible';
export const SET_IS_WARNING_MODAL_VISIBLE = 'set_is_warning_modal_visible';
export const SET_IS_MRI_SUPER_ADMIN_MODAL_VISIBLE =
  'set_is_mri_super_admin_modal_visible';
export const SET_IS_NEW_MRI_SUPER_ADMIN_MODAL_VISIBLE =
  'set_is_new_mri_super_admin_modal_visible';
