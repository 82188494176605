import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { StyledEngineProvider } from '@mui/material';
import { FiltersProvider } from 'modules/filters/context/filtersContext';
import { UserProvider } from 'modules/user/context/userContext';
import { ModalProvider } from 'modules/modal/context/modalContext';
import { AlertProvider } from 'modules/alert/context/alertContext';
import App from 'App';

const queryClient = new QueryClient();

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <AlertProvider>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH_DOMAIN}
        clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH_AUDIENCE}
        scope={process.env.REACT_APP_AUTH_SCOPE}
        cacheLocation={process.env.REACT_APP_AUTH_CACHE_LOCATION}
      >
        <FiltersProvider>
          <UserProvider>
            <BrowserRouter>
              <ModalProvider>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </ModalProvider>
            </BrowserRouter>
          </UserProvider>
        </FiltersProvider>
      </Auth0Provider>
    </AlertProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);
