import axios from 'axios';
import { useQuery } from 'react-query';
import { ADMIN_URL } from 'utils/constants';

const fetchAdminProfile = async () => {
  const response = await axios.get(ADMIN_URL);
  return response.data;
};

const thirtySecondStaleTime = 30000;

const useAdminProfile = () => {
  return useQuery('fetchAdminProfile', fetchAdminProfile, {
    staleTime: thirtySecondStaleTime,
  });
};

export default useAdminProfile;
