import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import filtersReducer, {
  initialState,
} from 'modules/filters/context/filtersReducer';
import {
  INCREASE_PAGE,
  DECREASE_PAGE,
  RESET_FILTERS,
  UPDATE_SEARCH_TYPE,
  UPDATE_SEARCH_VALUE,
  SET_SELECTED_DATASET,
  SET_SELECTED_DICOM_STORE,
  RESET_PAGE,
  SET_PROFILE_SELECTED_DATASET,
  SET_MRI_SUPER_ADMIN_MODE,
} from 'modules/filters/context/actions';

const FiltersContext = createContext();

export const FiltersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filtersReducer, initialState);

  const setProfileSelectedDataset = useCallback((dataset) => {
    dispatch({ type: SET_PROFILE_SELECTED_DATASET, payload: dataset });
  }, []);

  const setSelectedDataset = useCallback((dataset) => {
    dispatch({
      type: SET_SELECTED_DATASET,
      payload: {
        dataset,
        selectedDataStore: '',
      },
    });
  }, []);

  const setselectedDataStore = (store) => {
    dispatch({ type: SET_SELECTED_DICOM_STORE, payload: store });
  };

  const setMriSuperAdminMode = (bool) => {
    dispatch({ type: SET_MRI_SUPER_ADMIN_MODE, payload: bool });
  };

  const increasePage = () => {
    dispatch({ type: INCREASE_PAGE });
  };

  const decreasePage = () => {
    dispatch({ type: DECREASE_PAGE });
  };

  const resetPage = () => {
    dispatch({ type: RESET_PAGE, payload: 0 });
  };

  const resetFilters = () => {
    dispatch({ type: RESET_FILTERS });
  };

  const updateSearchType = (type) => {
    dispatch({ type: UPDATE_SEARCH_TYPE, payload: type });
  };

  const updateSearchValue = (value) => {
    dispatch({ type: UPDATE_SEARCH_VALUE, payload: value });
  };

  const value = useMemo(() => {
    return {
      ...state,
      increasePage,
      decreasePage,
      resetFilters,
      updateSearchType,
      updateSearchValue,
      setSelectedDataset,
      setselectedDataStore,
      resetPage,
      setProfileSelectedDataset,
      setMriSuperAdminMode,
    };
  }, [setProfileSelectedDataset, setSelectedDataset, state]);

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  );
};

FiltersProvider.propTypes = {
  children: PropTypes.node,
};

FiltersProvider.defaultProps = {
  children: {},
};

export const useFilters = () => {
  return useContext(FiltersContext);
};
