import React from 'react';
import { Box } from '@mui/material';
import DataStoreDropDown from 'modules/filters/components/DataStoreDropDown';
import DataSetDropDown from 'modules/filters/components/DatasetDropDown';
import SearchBox from 'modules/filters/components/SearchBox';
import { useFilters } from 'modules/filters/context/filtersContext';

const FiltersBar = () => {
  const { mriSuperAdminMode } = useFilters();

  return (
    <Box sx={styles.box}>
      <SearchBox />
      {!mriSuperAdminMode && (
        <>
          <DataSetDropDown />
          <DataStoreDropDown />
        </>
      )}
    </Box>
  );
};

const styles = {
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '1em 0.5em',
  },
};

export default FiltersBar;
