import React, { useCallback, useEffect, useState } from 'react';
import {
  TextField,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { Loading, Modal } from 'modules/common/components';
import UserRoles from 'modules/user/components/UserRoles';
import { useModal } from 'modules/modal/context/modalContext';
import { useUser } from 'modules/user/context/userContext';
import EvenlySpacedButtons from 'modules/common/components/Buttons/EvenlySpacedButtons';
import SummaryModal from 'modules/modal/components/SummaryModal';
import useMutationHooks from 'modules/user/hooks/useMutationHooks';
import {
  USER_ACCOUNT_TYPE,
  ADMIN_ACCOUNT_TYPE,
  ADMIN,
  USER,
  DATASET_ADMIN,
  EMAIL_VALIDATION_REGEX,
} from 'utils/constants';
import { createChangeObject } from 'modules/user/helpers';
import useAdminProfile from 'modules/admin/useAdminProfile';
import isSuperAdminOrAbove from 'modules/auth/helpers';

const initialState = {
  isNameError: false,
  isEmailError: false,
  isRoleChangesError: false,
  isFormError: false,
  isSavePressed: false,
  isFormChecked: false,
};

const NewUser = () => {
  const {
    isNewUserModalVisible,
    setIsNewUserModalVisible,
    setIsSummaryModalVisible,
    isSummaryModalVisible,
  } = useModal();

  const {
    name,
    email,
    setUserName,
    setUserEmail,
    accountType,
    setAccountType,
    rolesChanges,
    resetUser,
    datasetAuthorizationChanges,
    statusNotificationChanges,
  } = useUser();

  const { createUserMutation } = useMutationHooks();
  const { data: { authorizationLevel = '' } = {} } = useAdminProfile();

  const [state, setState] = useState(initialState);

  const {
    isNameError,
    isEmailError,
    isRoleChangesError,
    isFormError,
    isSavePressed,
    isFormChecked,
  } = state;

  const handleOpenSummaryModal = useCallback(() => {
    setIsSummaryModalVisible(true);
  }, [setIsSummaryModalVisible]);

  const handleNewUserModalClose = useCallback(() => {
    setIsNewUserModalVisible(false);
    setState(initialState);
    resetUser();
  }, [resetUser, setIsNewUserModalVisible]);

  const changesConfirmed = () => {
    const { changes, notificationChanges } = createChangeObject(
      accountType,
      rolesChanges,
      datasetAuthorizationChanges,
      statusNotificationChanges
    );
    createUserMutation.mutate({
      name,
      email,
      roleChanges: changes,
      notificationChanges,
      accountType,
    });
    setIsSummaryModalVisible(false);
  };

  const { isLoading, isSuccess, reset } = createUserMutation;

  const getRoleChangesLength = useCallback(() => {
    return (
      rolesChanges.length +
      Object.keys(datasetAuthorizationChanges || {}).length
    );
  }, [datasetAuthorizationChanges, rolesChanges.length]);

  const verifyInputs = useCallback(() => {
    let nameError = false;
    let emailError = false;

    if (!name.trim().length) {
      nameError = true;
    }
    const emailValidationRegex = new RegExp(EMAIL_VALIDATION_REGEX);
    if (!emailValidationRegex.test(email)) {
      emailError = true;
    }
    const roleChangesError = getRoleChangesLength() === 0;

    setState((currentState) => {
      return {
        ...currentState,
        isNameError: nameError,
        isEmailError: emailError,
        isRoleChangesError: roleChangesError,
        isFormError: nameError || emailError || roleChangesError,
      };
    });
  }, [email, getRoleChangesLength, name]);

  const handleCloseSummaryModal = () => {
    setIsSummaryModalVisible(false);
  };

  const handleSaveClick = () => {
    setState((currentState) => {
      return { ...currentState, isSavePressed: true };
    });
  };

  const handleOnUserNameChange = (e) => {
    setUserName(e.target.value);
    setState((currentState) => {
      return { ...currentState, isNameError: false };
    });
  };

  const handleOnUserEmailChange = (e) => {
    setUserEmail(e.target.value);
    setState((currentState) => {
      return { ...currentState, isEmailError: false };
    });
  };

  const handleOnUserTypeChange = (e) => {
    let value = '';
    if (e.target.value === ADMIN) {
      value = ADMIN_ACCOUNT_TYPE;
    } else {
      value = USER_ACCOUNT_TYPE;
    }
    setAccountType(value);
  };

  useEffect(() => {
    if (isSuccess) {
      handleNewUserModalClose();
      reset();
    }
  }, [handleNewUserModalClose, isSuccess, reset]);

  useEffect(() => {
    if (isSavePressed) {
      verifyInputs();
      setState((currentState) => {
        return { ...currentState, isSavePressed: false, isFormChecked: true };
      });
    }
  }, [isSavePressed, verifyInputs]);

  useEffect(() => {
    if (getRoleChangesLength() > 0) {
      setState((currentState) => {
        return { ...currentState, isRoleChangesError: false };
      });
    }
  }, [rolesChanges, datasetAuthorizationChanges, getRoleChangesLength]);

  useEffect(() => {
    if (isFormChecked && !isFormError) {
      handleOpenSummaryModal();
      setState((currentState) => {
        return { ...currentState, isFormChecked: false };
      });
    }
  }, [handleOpenSummaryModal, isFormChecked, isFormError]);

  if (isLoading) {
    return (
      <Modal title="Creating New User..." isOpen={isNewUserModalVisible}>
        <Loading />
      </Modal>
    );
  }

  return (
    <Modal
      title="New User"
      isOpen={isNewUserModalVisible}
      onClose={handleNewUserModalClose}
    >
      <Box sx={styles.box}>
        <TextField
          value={name}
          required
          error={isNameError}
          type="text"
          label="Name"
          onChange={handleOnUserNameChange}
          sx={styles.textField}
        />
        <br />
        <TextField
          value={email}
          required
          error={isEmailError}
          type="email"
          label="Email"
          sx={styles.textField}
          onChange={handleOnUserEmailChange}
        />
      </Box>
      {(isSuperAdminOrAbove(authorizationLevel) ||
        authorizationLevel === DATASET_ADMIN) && (
        <FormControl>
          <InputLabel>User Type</InputLabel>
          <Select
            value={accountType === ADMIN_ACCOUNT_TYPE ? ADMIN : USER}
            label="User Type"
            onChange={handleOnUserTypeChange}
            autoWidth
            sx={{ marginRight: '.5em', width: '100%' }}
          >
            <MenuItem value={USER}>User</MenuItem>
            <MenuItem value={ADMIN}>Admin</MenuItem>
          </Select>
        </FormControl>
      )}
      <UserRoles />
      <p style={{ color: isRoleChangesError ? 'red' : null }}>
        Summary: {getRoleChangesLength()} roles added
      </p>
      <EvenlySpacedButtons
        leftBtnText="Cancel"
        leftBtnOnClick={handleNewUserModalClose}
        leftBtnColor="inherit"
        rightBtnText="Save"
        rightBtnOnClick={handleSaveClick}
      />
      <SummaryModal
        isOpen={isSummaryModalVisible}
        onClose={handleCloseSummaryModal}
        onBtnClick={changesConfirmed}
        roleChanges={rolesChanges}
        datasetAuthorizationChanges={datasetAuthorizationChanges}
        name={name}
        email={email}
        picture={email}
      />
    </Modal>
  );
};

const styles = {
  box: { margin: '1em 0 0 .5em' },
  button: {
    height: '4em',
    minWidth: '10em',
    marginTop: '1em',
  },
  grid: { justifyContent: 'right' },
  textField: { flex: 1, margin: '0 0.5em 0.5em 0' },
};

export default NewUser;
