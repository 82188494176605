import React from 'react';
import { Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';

const EvenlySpacedButtons = ({
  leftBtnText,
  leftBtnOnClick,
  leftBtnColor,
  rightBtnText,
  rightBtnOnClick,
  rightBtnColor,
}) => {
  return (
    <Grid justifyContent="space-between" container>
      <Button
        onClick={leftBtnOnClick}
        sx={styles.button}
        variant="contained"
        color={leftBtnColor}
      >
        {leftBtnText}
      </Button>
      <Button
        onClick={rightBtnOnClick}
        sx={styles.button}
        variant="contained"
        color={rightBtnColor || 'success'}
      >
        {rightBtnText}
      </Button>
    </Grid>
  );
};

const styles = {
  button: { height: '4em', minWidth: '10em', marginTop: '1em' },
};

EvenlySpacedButtons.propTypes = {
  rightBtnText: PropTypes.string,
  leftBtnText: PropTypes.string,
  leftBtnColor: PropTypes.string,
  rightBtnColor: PropTypes.string,
  rightBtnOnClick: PropTypes.func,
  leftBtnOnClick: PropTypes.func,
};

EvenlySpacedButtons.defaultProps = {
  rightBtnText: '',
  leftBtnText: '',
  leftBtnColor: 'error',
  rightBtnColor: 'success',
  leftBtnOnClick: () => {},
  rightBtnOnClick: () => {},
};
export default EvenlySpacedButtons;
