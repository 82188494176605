/* eslint-disable react/prop-types */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Modal } from 'modules/common/components';
import EvenlySpacedButtons from 'modules/common/components/Buttons/EvenlySpacedButtons';
import { useUser } from 'modules/user/context/userContext';
import { ADMIN_ACCOUNT_TYPE } from 'utils/constants';

const WarningModal = ({ isOpen, onClose, onBtnClick }) => {
  const { accountType } = useUser();

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Warning">
      <Grid container direction="column">
        <Typography variant="h5" sx={styles.h5}>
          Are you sure you want to delete this user?
        </Typography>
        <Typography variant="h6" sx={styles.h6}>
          {accountType === ADMIN_ACCOUNT_TYPE
            ? 'Deleting an admin will revoke their authorization over all MRI systems. This cannot be undone!'
            : 'Deleting a user will revoke their access from all MRI systems. This cannot be undone!'}
        </Typography>
        <EvenlySpacedButtons
          leftBtnText="Cancel"
          leftBtnOnClick={onClose}
          leftBtnColor="inherit"
          rightBtnText="Confirm"
          rightBtnOnClick={onBtnClick}
          rightBtnColor="error"
        />
      </Grid>
    </Modal>
  );
};

const styles = {
  h5: { marginTop: '1em' },
  h6: { marginTop: '1em' },
};

export default WarningModal;
