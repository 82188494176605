import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useFilters } from 'modules/filters/context/filtersContext';
import AllUserRoles from 'modules/user/components/AllUserRoles';
import SingleUserRole from 'modules/user/components/SingleUserRole';

const UserRoles = () => {
  const { selectedDataStore } = useFilters();
  const [showAllRoles, setShowAllRoles] = useState(false);

  const render = () => {
    if (selectedDataStore && !showAllRoles) {
      return <SingleUserRole />;
    }
    return <AllUserRoles />;
  };

  return (
    <div>
      <Typography sx={styles.roleTypography} variant="h5">
        Roles
      </Typography>
      {selectedDataStore && (
        <Button
          sx={styles.button}
          onClick={() => setShowAllRoles((curVal) => !curVal)}
        >
          {showAllRoles ? 'Hide' : 'Show'} All Roles
        </Button>
      )}
      {render()}
    </div>
  );
};

const styles = {
  button: { display: 'block' },
  roleTypography: { margin: '.5rem 0' },
};

export default UserRoles;
