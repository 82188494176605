import React, { useCallback, useEffect } from 'react';
import UserBasicInformation from 'modules/user/components/UserBasicInformation';
import { useUser } from 'modules/user/context/userContext';
import { useModal } from 'modules/modal/context/modalContext';
import { Loading, Modal } from 'modules/common/components';
import WarningModal from 'modules/modal/components/WarningModal';
import RightJustifiedButton from 'modules/common/components/Buttons/RightJustifiedButton';
import useMutationHooks from 'modules/user/hooks/useMutationHooks';

const MriSuperAdminProfile = () => {
  const {
    setIsSummaryModalVisible,
    isMriSuperAdminModalVisible,
    isWarningModalVisible,
    setIsWarningModalVisible,
  } = useModal();

  const { userId, resetUser } = useUser();
  const { deleteMriSuperAdminMutation } = useMutationHooks();

  const { isLoading, isSuccess } = deleteMriSuperAdminMutation;

  const handleProfileClose = useCallback(() => {
    resetUser();
  }, [resetUser]);

  const handleDeleteClick = () => {
    setIsWarningModalVisible(true);
  };

  const handleWarningConfirm = () => {
    deleteMriSuperAdminMutation.mutate(userId);
    setIsSummaryModalVisible(false);
    setIsWarningModalVisible(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setIsSummaryModalVisible(false);
    }
  }, [isSuccess, setIsSummaryModalVisible]);

  if (isLoading) {
    return (
      <Modal
        title="Deleting MRI Super Admin..."
        isOpen={isMriSuperAdminModalVisible}
      >
        <Loading />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isMriSuperAdminModalVisible}
      onClose={handleProfileClose}
      title="MRI Super Admin Profile"
    >
      <UserBasicInformation />
      <RightJustifiedButton
        type="error"
        text="Delete Admin"
        onClick={handleDeleteClick}
      />
      <WarningModal
        isOpen={isWarningModalVisible}
        onClose={() => {
          setIsWarningModalVisible(false);
        }}
        onBtnClick={handleWarningConfirm}
      />
    </Modal>
  );
};

export default MriSuperAdminProfile;
