import React from 'react';
import { Grid, Typography } from '@mui/material';
import FiltersBar from 'modules/filters/components/FiltersBar';
import NewUser from 'modules/user/components/NewUser';
import NewDatasetModal from 'modules/modal/components/NewDatasetModal';
import NewDicomStoreModal from 'modules/modal/components/NewDicomStoreModal';
import UsersTable from 'modules/user/components/UsersTable';
import useAdminProfile from 'modules/admin/useAdminProfile';
import NewMenuButton from 'modules/dashboard/components/NewMenuButton';
import { Loading } from 'modules/common/components';
import UserProfile from 'modules/user/components/UserProfile';
import ErrorPage from 'modules/common/components/ErrorPage';
import MriSuperAdminButton from 'modules/dashboard/components/MriSuperAdminButton';
import MriSuperAdminProfile from 'modules/user/components/mriSuperAdmin/MriSuperAdminProfile';
import NewMriSuperAdmin from 'modules/user/components/mriSuperAdmin/NewMriSuperAdmin';

const Dashboard = () => {
  const { isLoading, isError } = useAdminProfile();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div style={styles.div}>
      <Grid sx={styles.grid} container>
        <Typography type="title" variant="h3">
          Dashboard
        </Typography>
        <div>
          <MriSuperAdminButton />
          <NewMenuButton />
        </div>
      </Grid>
      <FiltersBar />
      <UsersTable />
      <MriSuperAdminProfile />
      <NewMriSuperAdmin />
      <UserProfile />
      <NewUser />
      <NewDatasetModal />
      <NewDicomStoreModal />
    </div>
  );
};

const styles = {
  button: { height: '4em', minWidth: '10em', marginBottom: '3em' },
  div: { maxWidth: '55rem', margin: '0 auto' },
  grid: {
    marginBottom: '2rem',
    justifyContent: 'space-between',
  },
  grid2: {
    justifyContent: 'right',
  },
};

export default Dashboard;
