import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useFilters } from 'modules/filters/context/filtersContext';
import DatasetAdminCheckBox from 'modules/user/components/DatasetAdminCheckbox';
import RolesTable from './RolesTable';

const SingleUserRole = () => {
  const { selectedDataset, selectedDataStore } = useFilters();
  const [isDatasetAdmin, setIsDatasetAdmin] = useState(false);

  return (
    <>
      <Typography style={styles.typography}>Institution:</Typography>
      <div style={styles.div}>{selectedDataset}</div>
      <DatasetAdminCheckBox
        selectedDataset={selectedDataset}
        isDatasetAdmin={isDatasetAdmin}
        setIsDatasetAdmin={setIsDatasetAdmin}
      />
      {!isDatasetAdmin && (
        <RolesTable
          profileSelectedDataset={selectedDataset}
          stores={[selectedDataStore]}
        />
      )}
    </>
  );
};

const styles = {
  div: {
    display: 'inline-block',
    border: '2px solid rgba(0, 0, 0, 0.2)',
    padding: '1em 1.2em',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontSize: '1rem',
    fontWeight: '400',
  },
  typography: { display: 'inline', marginRight: '1em' },
};

export default SingleUserRole;
