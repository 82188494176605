import {
  SET_UPDATED_ROLES,
  SET_USER_TYPE,
  CHANGE_DATASET_AUTH,
  RESET_USER,
  SET_USER,
  SET_USER_EMAIL,
  SET_USER_NAME,
  SET_UPDATED_STATUS_NOTIFICATIONS,
} from 'modules/user/context/actions';
import { USER_ACCOUNT_TYPE } from 'utils/constants';

export const initialState = {
  userId: '',
  name: '',
  email: '',
  picture: '',
  accountType: USER_ACCOUNT_TYPE,
  roles: {},
  updatedRoles: {},
  rolesChanges: [],
  statusNotifications: {},
  updatedStatusNotifications: {},
  statusNotificationChanges: [],
  datasetAuthorization: [],
  datasetAuthorizationChanges: {},
};

const userReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_USER: {
      const {
        user_id: userId,
        name,
        email,
        picture,
        roles,
        accountType,
        datasetAuthorization = [],
        statusNotifications = {},
      } = payload;
      return {
        ...state,
        userId,
        name,
        email,
        picture,
        roles,
        accountType,
        datasetAuthorization,
        statusNotifications,
        updatedStatusNotifications: JSON.parse(
          JSON.stringify(statusNotifications) // make a deep copy
        ),
      };
    }

    case SET_USER_NAME: {
      return { ...state, name: payload };
    }

    case SET_USER_EMAIL: {
      return { ...state, email: payload };
    }

    case SET_USER_TYPE:
      return {
        ...state,
        accountType: payload,
        updatedRoles: {},
        rolesChanges: [],
        datasetAuthorizationChanges: {},
        updatedStatusNotifications: {},
        statusNotificationChanges: [],
      };

    case SET_UPDATED_ROLES: {
      const { roles, changes } = payload;
      return { ...state, updatedRoles: roles, rolesChanges: changes };
    }

    case RESET_USER:
      return { ...initialState };

    case CHANGE_DATASET_AUTH: {
      return { ...state, datasetAuthorizationChanges: payload };
    }

    case SET_UPDATED_STATUS_NOTIFICATIONS: {
      const { updatedStatusNotifications, changes } = payload;

      return {
        ...state,
        updatedStatusNotifications,
        statusNotificationChanges: changes,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
