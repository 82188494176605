import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Loading, Modal } from 'modules/common/components';
import RightJustifiedButton from 'modules/common/components/Buttons/RightJustifiedButton';
import { useModal } from 'modules/modal/context/modalContext';
import useGCPMutationHooks from 'modules/modal/hooks/useGCPMutationHooks';
import useAdminProfile from 'modules/admin/useAdminProfile';
import { ROOT_ADMIN } from 'utils/constants';

const NewDatasetModal = () => {
  const { isNewDatasetModalVisible, setIsNewDatasetModalVisible } = useModal();
  const [newDatasetName, setNewDatasetName] = useState('');
  const [isDevDataset, setIsDevDataset] = useState(false);
  const { newDatasetMutation } = useGCPMutationHooks();
  const { data: { authorizationLevel = '' } = {} } = useAdminProfile();

  const handleNewDatasetModalClose = useCallback(() => {
    setIsNewDatasetModalVisible(false);
    setNewDatasetName('');
    setIsDevDataset(false);
  }, [setIsNewDatasetModalVisible]);

  const { isLoading, isSuccess, reset } = newDatasetMutation;

  useEffect(() => {
    if (isSuccess) {
      handleNewDatasetModalClose();
      reset();
    }
  }, [handleNewDatasetModalClose, isSuccess, reset]);

  const handleOnSaveButtonClick = () => {
    newDatasetMutation.mutate({ dataset: newDatasetName, isDevDataset });
  };

  const handleOnChangeDatasetNameTextField = ({ target: { value } }) => {
    setNewDatasetName(value);
  };

  const handleOnClickDevCheckbox = () => {
    setIsDevDataset((prev) => !prev);
  };

  const renderDevSiteCheckbox = () => {
    if (authorizationLevel === ROOT_ADMIN) {
      return (
        <>
          <Typography variant="subtitle2" sx={{ color: 'red' }}>
            DO NOT select the checkbox below if this is a production site.
            Development Institutions are only for internal Promaxo use.
          </Typography>
          <FormControlLabel
            sx={styles.form}
            control={<Checkbox />}
            label="Development Institution"
            checked={isDevDataset}
            onChange={handleOnClickDevCheckbox}
            labelPlacement="end"
          />
        </>
      );
    }

    return null;
  };

  if (isLoading) {
    return (
      <Modal
        isOpen={isNewDatasetModalVisible}
        title="Creating New Institution..."
      >
        <Loading />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isNewDatasetModalVisible}
      onClose={handleNewDatasetModalClose}
      title="New Institution"
    >
      <TextField
        type="text"
        label="Name"
        value={newDatasetName}
        sx={styles.textField}
        onChange={handleOnChangeDatasetNameTextField}
      />
      {renderDevSiteCheckbox()}
      <Typography variant="subtitle2">
        Note: You may have to refresh this app for new institution to show up
      </Typography>
      <RightJustifiedButton text="Save" onClick={handleOnSaveButtonClick} />
    </Modal>
  );
};

const styles = {
  form: { marginBottom: '1em' },
  textField: { flex: 1, margin: '1em 0.5em 1em 0', width: '100%' },
};

export default NewDatasetModal;
